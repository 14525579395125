import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/panelne/1.png';
import SlideThird from '../../assets/panelne/2.webp';
import SlideSecond from '../../assets/panelne/5.webp';
import SlideFourth from '../../assets/panelne/4.webp';
import SlideFifth from '../../assets/panelne/8.webp';

import SlideFirstPhone from '../../assets/panelne_mobile/panel1.webp';
import SlideSecondPhone from '../../assets/panelne_mobile/panel2.webp';
import SlideThirdPhone from '../../assets/panelne_mobile/panel3.webp';
import SlideFourthPhone from '../../assets/panelne_mobile/panel4.webp';
import SlideFifthPhone from '../../assets/panelne_mobile/panel5.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Panelne zavese',
    message: 'Sveden izgled unapređuje estetiku svakog doma.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Panelne zavese',
    message: 'Dostupne u različitim bojama i dezenima.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Panelne zavese',
    message: 'Pružaju preciznu kontrolu nad svetlošću.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Panelne zavese',
    message: 'Izrađuju se po vašim dimenzijama.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Panelne zavese',
    message: 'Pružaju dodatnu izolaciju i utiču na temperaturu.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
];

const Panelne = () => {
  return (
    <>
      <Helmet>
        <title>Panel zavese - Zavese za velike staklene površine - DaLux</title>
        <meta
          name="title"
          content="Panel zavese - Zavese za velike staklene površine - DaLux"
        />
        <meta
          name="description"
          content="Panel zavese su idealno rešenje za pokrivanje velikih staklenih površina. Pogledajte ponudu na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/panel-zavese" />

        <meta
          property="og:title"
          content="Panel zavese - Zavese za velike staklene površine - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/panel-zavese" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Panel zavese su idealno rešenje za pokrivanje velikih staklenih površina. Pogledajte ponudu na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.c848af74350ab7afe966.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.dalux.rs/panel-zavese",
    "name": "Panel zavese - Zavese za velike staklene površine - DaLux",
    "description": "Panel zavese su idealno rešenje za pokrivanje velikih staklenih površina. Pogledajte ponudu na sajtu Dalux.rs.",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Početna",
          "item": "https://www.dalux.rs"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Panel zavese",
          "item": "https://www.dalux.rs/panel-zavese"
        }
      ]
    }
  }
  `}
        </script>

        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Panel zavese",
    "image": "https://www.dalux.rs/static/media/1.c848af74350ab7afe966.png",
    "description": "Panel zavese su idealno rešenje za pokrivanje velikih staklenih površina. Pogledajte ponudu na sajtu Dalux.rs.",
    "url": "https://www.dalux.rs/panel-zavese",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "RSD",
      "price": "Cena na upit",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "DaLux"
      }
    }
  }
  `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koliko koštaju panelne zavese?',
            message: `Cena garnišne za panelne zavese iznosi od 3.500rsd/m -6.500rsd/m u zavisnosti koliko kanala je potrebno. Cena platna za panelne zavese iznosi od 2.500rsd/m2 - 3.500 rsd/m2.`,
          },
          {
            title:
              'Da li panelne zavese mogu da služe za pregrađivanje prostorije?',
            message:
              'Naravno, panelne zavese su idealne za pregrađivanje prostora.',
          },
          {
            title: `Kako se održavaju panelne zavese?`,
            message: `Panelne zavese je potrebno samo prebrisati vlažnom krpom. Platna su antialergijska i antistatična tako da ne sakupljaju prašinu niti upijaju mirise.`,
          },
          {
            title: `Kolika je maksimalna visina za panelne zavese?`,
            message: `Maksimalna visina koju je moguće izraditi iznosi 4 metra.`,
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za panelne zavese je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default Panelne;
