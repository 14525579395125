import React from 'react';
import { Container } from '../../components';
import photo1 from '../../assets/blog/article1/sarene-zavese.webp';
import photo2 from '../../assets/blog/article1/zavese-do-poda.webp';
import photo3 from '../../assets/blog/article1/zavese-koje-se-vuku-po-podu.webp';
import photo4 from '../../assets/blog/article1/garnisne.webp';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>5 saveta da vaša soba bude veća pomoću zavesa - DaLux.rs</title>
        <meta
          name="title"
          content="5 saveta da vaša soba bude veća pomoću zavesa - DaLux.rs"
        />
        <meta
          name="description"
          content="Saznajte kako da vaša soba izgleda veća uz pomoć visokih zavesa, zavesa do poda, zavesa koje se vuku po podu i odgovarajućih garnišni."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/blog/5-saveta-da-vasa-soba-bude-veca-pomocu-zavesa"
        />

        <meta
          property="og:title"
          content="5 saveta da vaša soba bude veća pomoću zavesa - DaLux.rs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/blog/5-saveta-da-vasa-soba-bude-veca-pomocu-zavesa"
        />
        <meta property="og:site_name" content="DaLux.rs" />
        <meta
          property="og:description"
          content="Saznajte kako da vaša soba izgleda veća uz pomoć visokih zavesa, zavesa do poda, zavesa koje se vuku po podu i odgovarajućih garnišni."
        />
        <meta property="og:locale" content="sr_RS" />
      </Helmet>

      <Container>
        <div
          style={{
            color: 'black',
            flexDirection: 'column',
            minHeight: '800px',
            paddingTop: '120px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            className={'articleBack'}
            onClick={() => {
              window.location = '/blog';
            }}
          >
            {'< Nazad na Blog'}
          </div>

          <div className={'articleTitle'}>
            <h1>5 saveta da vaša soba bude veća pomoću zavesa</h1>
            {/* <small className={'articleDate'}>12. septembar, 2022.</small> */}
          </div>

          <p className={'articleBody'}>
            <img src={photo1} />
            Svako od nas je u jednom trenutku poželeo da njegov životni prostor
            bude znatno prostraniji nego što jeste. Ono što, međutim, često bude
            prepreka za većinu je vreme i novac koji su potrebni kako biste
            napravili odgovarajuće promene i preuredili svoj enterijer. Kao
            jedno od najjednostavnijih rešenja se ističu – zavese. U nastavku
            teksta smo izdvojili 4 saveta koji će pomoći da vaša soba u stanu
            ili kući deluje znatno veća.
            <br />
            <br />
            <h3>1. Visoke Zavese S Dugim Vertikalnim Prugama</h3>
            Visoke zavese s dugim vertikalnim prugama ili šarama stvaraju
            iluziju visine, što je odlično za niske zidove. Ukoliko, međutim,
            imate kratke zavese sa horizontalnim prugama, one će znatno skratiti
            visinu vašeg zida i učiniti da soba izgleda manja.
            <br />
            <img src={photo1} />
            <br />
            <br />
            <h3>2. Zavese Iznad Prozorske Daske</h3>
            Zavese koje vise tačno iznad vaše prozorske daske odlikuje najmanja
            dužina. Ova dužina Zavese je odlična za male prozore iznad
            kuhinjskih sudopera, kupatila i prozora iznad radijatora.
            <br />
            <br />
            <h3>3. Zavese Do Poda</h3>
            Mogu se koristiti u različitim enterijerima. Zavese do poda idu od
            vrha prozora do oko 1 centimetara iznad poda. Pošto ne dodiruju pod,
            zaštićene su od prljavštine. Kako vise ravno, stvaraju uglađen,
            ujednačen i elegantan izgled i povećacaju visinu vaših zidova.
            <br />
            <img src={photo2} />
            <br />
            Prilikom postavljanja zavesa do poda, od izuzetne važnosti je da u
            obzir uzmete širinu. Idealno je postaviti zavese po 20 cm levo i
            desno od spaletne (podlozno promenama, minimum 10 cm). Ovo pomaže
            panelima zavesa da zadrže svoje nabore bez preopterećenja. Zavese do
            poda dobro se uklapaju u dnevne i spavaće sobe, posebno kada je
            nameštaj udaljen od zidova. Takođe su odlične za prostorije u kojima
            je veliki prometom.
            <br />
            <br />
            <h3>4. Zavese Koje Se Vuku Po Podu</h3>
            Zavese koje se vuku po podu su odlične za sobe sa malim prometom kao
            što su spavaće sobe i trepezarije. Na taj način ćete uživati u
            Lepršavim Zavesama bez opasnosti da se spotaknete. Međutim,
            izbegavajte ovaj tip zavesa ukoliko imate decu i kućne ljubimce jer
            je moguće da se brzo zaprljaju.
            <br />
            <img src={photo3} />
            <br />
            Ove zavese je takođe najbolje postaviti iznad prozora, a idealno u
            visini plafona. Vaš prostor postaje sve veći i duži svaki put kada
            povučete i zatvorite zavese, a daju i dramatičan, ali i luksuznan
            izgled prostoriji. Kako biste postigli pun efekat uz pomoć zavesa
            potrebne su i…
            <br />
            <br />
            <h3>5. Odgovarajuće Garnišne</h3>
            Zavese je najbolje postaviti na široke Garnišne koje se protežu
            izvan okvira prozora. Na ovaj način možete odmaknuti zavese od
            prozora i omogućiti ne samo maksimalan prodor svetlosti u
            prostoriju, nego će ona delovati znatno veća. Za najbolje rezultate,
            postavite garnišne visoko iznad prozora.
            <br />
            <img src={photo4} />
            <br />U zavisnosti od veličine vaših prozora i enterijera odaberite
            odgovarajući tip zavesa i komplementirajte ih uz garnišne. Na ovaj
            način ćete biti u mogućnosti da uz malu investiciju i najmanji
            prostor učinite prostranijim i stilizovanijim.
          </p>
        </div>
      </Container>
    </>
  );
};
