import React from 'react';
import bgImage from '../../assets/architect/architecht-hero.png';
import './programZaArhitekte.css';
import {
  Container,
  ProgramHeader,
  ProgramSection,
  ProgramSectionItem,
} from '../../components';
import saradnici from '../../assets/architect/buduci-saradnici.png';
import kvProizvodi from '../../assets/architect/1.png';
import resenja from '../../assets/architect/2.png';
import podrska from '../../assets/architect/3.png';
import timski from '../../assets/architect/4.png';
import ceo from '../../assets/architect/CEO.png';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600;

const ProgramZaArhitekte = () => {
  return (
    <>
      <Helmet>
        <title>
          Program saradnje sa arhitektama i dizajnerima enterijera - DaLux
        </title>
        <meta
          name="title"
          content="Program saradnje sa arhitektama i dizajnerima enterijera - DaLux"
        />
        <meta
          name="description"
          content="Ukoliko ste arhitekta ili dizajner enterijera koji je zainteresovan za saradnju na projektima, posetite Dalux.rs ili nas kontaktirajte."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/program-za-arhitekte"
        />

        <meta
          property="og:title"
          content="Program saradnje sa arhitektama i dizajnerima enterijera - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/program-za-arhitekte"
        />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Ukoliko ste arhitekta ili dizajner enterijera koji je zainteresovan za saradnju na projektima, posetite Dalux.rs ili nas kontaktirajte."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/images/social/program-za-arhitekte.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <script type="application/ld+json">
          {`{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Program za arhitekte",
      "url": "https://www.dalux.rs/program-za-arhitekte",
      "description": "Detalji o našem programu za arhitekte.",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Program za arhitekte",
            "item": "https://www.dalux.rs/program-za-arhitekte"
          }
        ]
      }
    }`}
        </script>
      </Helmet>
      <div className="pageArchitect">
        <ProgramHeader
          title="Pozivamo Vas na saradnju."
          message="Program za arhitekte i dizajnere enterijera."
          image={bgImage}
        />

        <Container>
          <ProgramSection layout={desktop ? 'leftImageReverse' : 'rightImage'}>
            <ProgramSectionItem
              title="Dragi budući saradnici,"
              message="Sa zadovoljstvom Vas pozivamo da se pridružite našoj kompaniji na budućim projektima. Posvećeni smo pružanju inovativnih i visokokvalitetnih rešenja za domove i kancelarijske prostore u industriji zaštite od sunca. Kao lideri u ovoj oblasti, smatramo da je saradnja sa stručnjacima ključna za postizanje novih rezultata. Želimo da Vam pružimo priliku da svojom kreativnošću i ekspertizom doprinesete našem timu i da zajedno gradimo budućnost."
              image={saradnici}
              imageClassName={'programFullHeight'}
            />
          </ProgramSection>

          <ProgramSection layout="rightImage">
            <ProgramSectionItem
              title="Kvalitetni proizvodi"
              message="Pažljivo osmišljen dizajn, pouzdana izrada i širok asortiman proizvoda je ono na šta smo izrazito ponosni. Zavese, Rolo Zavese, Rimske Zavese, Drveni Venecijaneri samo su neke od stavki koje čine našu široku paletu proizvoda. Estetski su prilagođeni, pouzdani i funkcionalni u isto vreme."
              image={kvProizvodi}
            />
          </ProgramSection>

          <ProgramSection layout={desktop ? 'leftImageReverse' : 'rightImage'}>
            <ProgramSectionItem
              title="Prilagođena rešenja"
              message="Svakom projektu pristupa se drugačije jer svaki projekat ima jedinstvene zahteve i ciljeve. Naš tim stručnjaka u oblasti dizajna i proizvodnje spreman je da sarađuje sa Vama kako bi zajedno došli do rešenja koje odgovara vašim potrebama i ciljevima."
              image={resenja}
            />
          </ProgramSection>

          <ProgramSection layout="rightImage">
            <ProgramSectionItem
              title="Tehnička podrška"
              message="Opremljeni smo najmodernijom tehnologijom i timom stručnjaka koji je tu za Vas od prvog poziva ili dolaska u salon pa sve do završetka instalacija. Obezbeđujemo detaljne tehničke informacije, uzorke materijala i kompletnu uslugu izrade, montaže i servisa."
              image={podrska}
            />
          </ProgramSection>
          <ProgramSection layout={desktop ? 'leftImageReverse' : 'rightImage'}>
            <ProgramSectionItem
              title="Timski rad"
              message="Partnerstvo i timski rad su ključni u našem poslovanju. Vaše iskustvo i stručnost u arhitekturi i dizajnu enterijera upotpuniće našu profesionalnost u proizvodnji sistema zaštite od sunca. Zajedno radimo kako bismo postigli inovativna rešenja za naše klijente i stvorili nove, bolje proizvode."
              image={timski}
            />
          </ProgramSection>

          <ProgramSection layout="fullWidthImageRight" className="ceoSection">
            <ProgramSectionItem
              message={
                <>
                  <p>
                    Ako ste zainteresovani za saradnju sa našom kompanijom za
                    zavese, molimo Vas da nas kontaktirate putem priloženih
                    kontaktnih informacija.
                  </p>
                  <p>
                    Rado ćemo se sastati sa Vama kako bismo razgovarali o Vašim
                    projektima i pružili Vam više informacija o našoj kompaniji
                    i proizvodima.
                  </p>
                  <p>
                    Radujemo se mogućnosti da zajedno stvaramo spektakularne
                    enterijere koji kombinuju funkcionalnost i estetiku
                  </p>
                  <p>S poštovanjem,</p> <h5>Damjan Andonovski</h5>
                  <h5>CEO @ Dalux</h5>
                </>
              }
              image={ceo}
              imageClassName="ceoImage"
            />
          </ProgramSection>
        </Container>
      </div>
    </>
  );
};
export default ProgramZaArhitekte;
