import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { StepTwoWrapper } from '../../containers/ShopNew/Shop.styled';
import { CartContext } from '../../CartContext';
import { CartItem } from './CartItem';

export const OrderReview = ({}) => {
  const { userInfo, cart } = useContext(CartContext);

  return (
    <StepTwoWrapper>
      <Stack alignItems="flex-start" width="100%">
        <Typography variant="h5" mt={4} alignSelf="center">
          Pregled porudžbine
        </Typography>
        <div className={'content'}>
          <hr style={{ width: '100%', margin: '5px 0' }} />
          <div className="list">
            {cart.map((cartProduct) => {
              return <CartItem cartProduct={cartProduct} />;
            })}
            <br />
          </div>
          <hr style={{ width: '100%', margin: '5px 0' }} />
          Ukupna cena porudžbine:{' '}
          <strong>
            {cart?.reduce((a, c) => a + c.price, 0).toFixed(2)} RSD
          </strong>
          <p className="pdv">
            Sve cene su sa uračunatim PDV-om i nema dodatnih ili skrivenih
            troškova. Troškove pošiljke snosi kupac.
          </p>
          <hr style={{ width: '100%', margin: '5px 0' }} />
          <Typography variant="h6" mt={4}>
            Podaci o dostavi
          </Typography>
          <Stack sx={{ fontSize: '16px', textAlign: 'left' }}>
            <p>
              Ime i Prezime: {userInfo.name} {userInfo.surname}
            </p>
            <p>Telefon: {userInfo.phone}</p>
            <p>Email: {userInfo.email}</p>
            <p>Ulica: {userInfo.street}</p>
            <p>Kućni broj: {userInfo.houseNo}</p>
            <p>Broj stana: {userInfo.apartmentNo}</p>
            <p>Grad: {userInfo.city}</p>
            <p>Poštanski broj: {userInfo.postNo}</p>
            <p>Dodatne informacije: {userInfo.info}</p>
          </Stack>
        </div>
      </Stack>
    </StepTwoWrapper>
  );
};
