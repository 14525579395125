import React from 'react';
import bgImage from '../../assets/korporativni/hero.png';
import {
  Container,
  ProgramHeader,
  ProgramSection,
  ProgramSectionItem,
} from '../../components';
import korpFirst from '../../assets/korporativni/1.webp';
import sectionFullWidth from '../../assets/korporativni/2.png';
import sectionThird from '../../assets/korporativni/3.png';
import { Helmet } from 'react-helmet';

const KorporativniProgram = () => {
  return (
    <>
      <Helmet>
        <title>
          Korporativni program zaštite od sunca u komercijalnom prostoru - DaLux
        </title>
        <meta
          name="title"
          content="Korporativni program zaštite od sunca u komercijalnom prostoru - DaLux"
        />
        <meta
          name="description"
          content="Specijalizovani program za dizajn enterijera poslovnog prostora koji omogućava udobnost, ali i adekvatnu zaštitu od sunca. Posetite sajt Dalux.rs."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/korporativni-program"
        />

        <meta
          property="og:title"
          content="Korporativni program zaštite od sunca u komercijalnom prostoru - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/korporativni-program"
        />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Specijalizovani program za dizajn enterijera poslovnog prostora koji omogućava udobnost, ali i adekvatnu zaštitu od sunca. Posetite sajt Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/hero.41c3f6b784f1e7e6de71.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1002" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Korporativni program",
              "url": "https://www.dalux.rs/korporativni-program",
              "description": "Saznajte više o našem korporativnom programu za preduzeća.",
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Početna",
                    "item": "https://www.dalux.rs/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Korporativni program",
                    "item": "https://www.dalux.rs/korporativni-program"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>

      <div>
        <ProgramHeader
          title="Korporativni program"
          message="Sistemi zaštite od sunca za sve vrste poslovnih objekata."
          image={bgImage}
        />
        <Container>
          <ProgramSection layout="rightImage">
            <ProgramSectionItem
              title="Profesionalni dizajn enterijera"
              message="Naš profesionalni dizajn enterijera pruža savršen spoj funkcionalnosti i estetike, nudeći visokokvalitetne sisteme za zaštitu od sunca koji istovremeno poboljšavaju udobnost prostora i utiču na izgled. Tim stručnjaka je tu da osigura da vaš prostor bude zaštićen od prekomernog sunčevog svetla, ali i da isporuka i montaža budu u dogovorenom roku. Tu smo da Vam omogućimo, da u najkraćem periodu, uživate u svim prednostima naših rešenja."
              image={korpFirst}
              imageClassName={'programFullHeight'}
            />
          </ProgramSection>

          <ProgramSection layout="fullWidthImage">
            <ProgramSectionItem image={sectionFullWidth} />
          </ProgramSection>

          <ProgramSection layout="leftImageReverse">
            <ProgramSectionItem
              title="Sistemi zaštite od sunca"
              message="Sistemi zaštite od sunca i tim stručnjaka koji radi na dizajniranju i proizvodnji stvara visoko funkcionalne proizvode, koji Vam omogućavaju da regulišete ulazak UV zraka u vaše prostorije i sprečite izbleđivanje nameštaja, podova, zidova.. Ovi sistemi uključuju rolo zavese, zebra zavese, trakaste zavese kao i zip."
              image={sectionThird}
              imageClassName={'programFullHeight'}
            />
          </ProgramSection>
        </Container>
      </div>
    </>
  );
};
export default KorporativniProgram;
