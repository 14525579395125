import React, { useState, useEffect } from 'react';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const getNextCheckpoint = () => {
  const now = new Date();
  // Postavljamo trenutno vreme na početak dana (ponoć)
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const msPer48Hours = 48 * 60 * 60 * 1000; // milisekundi u 48 sati

  let nextCheckpoint = new Date(startOfDay.getTime() + msPer48Hours);

  // Ako je trenutno vreme već prošlo prvi checkpoint, izračunavamo sledeći
  while (nextCheckpoint <= now) {
    nextCheckpoint = new Date(nextCheckpoint.getTime() + msPer48Hours);
  }

  return nextCheckpoint.getTime();
};

const Timer = ({ initialHours = 48 }) => {
  const [endTime, setEndTime] = useState(getNextCheckpoint());

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date().getTime() >= endTime) {
        setEndTime(getNextCheckpoint());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <div
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          marginRight: '35px',
          marginTop: '8px',
        }}
      >
        PREOSTALO JOS:
      </div> */}
      <FlipClockCountdown
        to={endTime}
        renderMap={[false, true, true, true]}
        labels={['', 'SATI', 'MINUTA', 'SEKUNDI']}
        labelStyle={{
          fontSize: 10,
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
        digitBlockStyle={{
          width: 20,
          height: 40,
          fontSize: 20,
          backgroundColor: '#ff3131',
        }}
        dividerStyle={{ color: 'white', height: 1 }}
        separatorStyle={{ color: 'red', size: '6px' }}
        duration={0.5}
      />
    </div>
  );
};

export default Timer;
