import React from 'react';
import bgImage from '../../assets/hotel/hotel-hero.png';
import {
  Container,
  ProgramHeader,
  ProgramSection,
  ProgramSectionItem,
} from '../../components';
import hotelFirst from '../../assets/hotel/1.webp';
import sectionFirst from '../../assets/hotel/2.png';
import sectionTapete from '../../assets/hotel/3.png';
import sectionRaneri from '../../assets/hotel/4.png';
import sectionFullWidth from '../../assets/hotel/5.png';
import './hotelskiProgram.css';
import { Helmet } from 'react-helmet';

const HotelskiProgrami = () => {
  return (
    <>
      <Helmet>
        <title>
          Hotelski program - Zavese, draperije i tapete za hotele - DaLux
        </title>
        <meta
          name="title"
          content="Hotelski program - Zavese, draperije i tapete za hotele - DaLux"
        />
        <meta
          name="description"
          content="Specijalizovani hotelski program za uređenje enterijera zavesama, draperijama i tapetama visokog kvaliteta sa Fire resistant sertifikatima."
        />
        <link rel="canonical" href="https://www.dalux.rs/hotelski-program" />

        <meta
          property="og:title"
          content="Hotelski program - Zavese, draperije i tapete za hotele - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/hotelski-program"
        />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Specijalizovani hotelski program za uređenje enterijera zavesama, draperijama i tapetama visokog kvaliteta sa Fire resistant sertifikatima."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/hotel-hero.47aa5446641d4063a7d0.png"
        />
        <meta property="og:image:width" content="1440" />
        <meta property="og:image:height" content="501" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Hotelski program",
              "url": "https://www.dalux.rs/hotelski-program",
              "description": "Detalji o našem hotelskom programu za unapređenje hotelskog enterijera.",
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Početna",
                    "item": "https://www.dalux.rs/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Hotelski program",
                    "item": "https://www.dalux.rs/hotelski-program"
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>

      <div>
        <ProgramHeader
          title="Hotelski program"
          message="Sistemi zaštite od sunca i hotelska oprema."
          image={bgImage}
        />

        <Container>
          <ProgramSection layout="rightImage">
            <ProgramSectionItem
              title="Dizajn gostoprimstva"
              message="Upoznajte naš ekskluzivni hotelski program za uređenje prostora
                koji uključuje visokokvalitetne zavese i draperije, elegantne
                tapete i luksuzne dekorativne jastuke. Raznolika ponuda,
                omogućava personalizaciju svakog hotela, stvarajući atmosferu
                koja odiše stilom, elegancijom i udobnošću. Materijali koje
                koristimo poseduju Fire Resistant setrifikate, a kompanija
                poseduje B1, ISO 9001 sertifikat. Dopustite nam da vašem
                prostoru dodamo jedinstvenu i sofisticiranu notu koja će
                impresionirati kako Vas, tako i vaše goste."
              image={hotelFirst}
              imageClassName={'programFullHeight'}
            />
          </ProgramSection>
          <ProgramSection layout="items">
            <ProgramSectionItem
              title="Zavese i draperije"
              message="Zavese i draperije pružaju privatnost gostima koja je izrazito
            bitna za njihov boravak. Dopuštaju kontrolu nad svetlosti koja
            ulazi u prostoriju, a istovremeno utiču na temperaturu.
            Doprinose luksuznom izgledu i utiču na privlačnost za nove
            goste."
              image={sectionFirst}
            />
            <ProgramSectionItem
              title="Tapete"
              message="Širok asortiman boja i dezena pružiće vašim sobama elegantan i
            atraktivan ambijent. Trajnost i otpornost tapeta na habanje,
            osigurava dugotrajnu lepotu prostorije. Tapete brzo i lako
            možete promeniti i prilagoditi različitim potrebama i zahtevima."
              image={sectionTapete}
            />
            <ProgramSectionItem
              title="Dekorativni jastuci i raneri"
              message=" Dekorativni jastuci predstavljaju savršen izbor jer unose
            toplinu, udobnost i estetsku privlačnost u hotelske sobe. Dolaze
            u raznovrsnim bojama, teksturama, oblicima i punjenjima. Osim
            što utiču na estetiku, ovi jastuci pružaju dodatnu podršku i
            udobnost gostima, a mogu se kombinovati sa zavesama i
            draperijama."
              image={sectionRaneri}
            />
          </ProgramSection>
          <ProgramSection layout="fullWidthImage">
            <ProgramSectionItem image={sectionFullWidth} />
          </ProgramSection>
        </Container>
      </div>
    </>
  );
};
export default HotelskiProgrami;
