import React, { useEffect, useState } from 'react';
import Container from '../container/Container';
import './programHeader.css';

const RESIZE_WIDTH = 768;

const ProgramHeader = ({ title, message, image, imageMobile }) => {
  const [bgImg, setBgImg] = useState(
    imageMobile
      ? window.innerWidth > RESIZE_WIDTH
        ? image
        : imageMobile
      : image
  );

  useEffect(() => {
    if (!imageMobile) {
      return;
    }
    const handleResize = () => {
      setBgImg(window.innerWidth > RESIZE_WIDTH ? image : imageMobile);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [image, imageMobile]);
  return (
    <div
      className="programHeader"
      style={{ backgroundImage: `url(${bgImg})`, backgroundPosition: 'center' }}
    >
      <Container>
        <div className="programHeaderHero">
          <h1>{title}</h1>
          <p>{message}</p>
        </div>
      </Container>
    </div>
  );
};
export default ProgramHeader;
