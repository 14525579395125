import styled from 'styled-components';
import React, { useEffect } from 'react';
import logo from '../../assets/logo.png';

export const SplashScreen = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const loadingBar = document.querySelector('.loading-bar');
      if (loadingBar) {
        loadingBar.classList.add('loading');
      }
    }, 50); // Start loading bar after 50ms

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="splashScreen">
      <img className="splasScreenLogo" src={logo} alt="Client Logo" />
      <div className="loading-bar"></div>
    </div>
  );
};

export default SplashScreen;
