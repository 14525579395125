import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  ButtonLink,
  Section,
  SectionContent,
  SliderKnobs,
} from '../../components';
import throttle from 'lodash.throttle';
import '../buttonLink/buttonLink.css';

import swipeLeft from '../../assets/swipe_left.gif';
import swipeUp from '../../assets/swipe_up.gif';

const Slider = ({ sliders }) => {
  const [selected, setSelected] = useState(0);
  let startX = null;
  let startY = null;
  const [isScrolling, setIsScrolling] = useState(false);
  const mobileScorll = window.innerWidth < 800;
  const [scrollTID, setScrollTID] = useState(null);

  const handleWheel = (e) => {
    if (mobileScorll) {
      return;
    }

    if (e.deltaX < 10 && e.deltaX > -10 && e.deltaY < 10 && e.deltaY > -10) {
      setIsScrolling(false);
    }

    //If its still not finished scolling dont do anything
    if (isScrolling) {
      return;
    }

    //Debounce the function so it doesnt trigger too often
    const throttledFunction = throttle(() => {
      const deltaY = mobileScorll ? 0 : e.deltaY;
      const deltaX = e.deltaX;
      let scrolling = false;
      if (deltaX > 50 || deltaY > 50) {
        setIsScrolling(true);
        scrolling = true;
        setSelected((oldValue) => oldValue + 1);
      } else if (deltaX < -50 || deltaY < -50) {
        setIsScrolling(true);
        scrolling = true;
        setSelected((oldValue) => oldValue - 1);
      }

      if (scrolling) {
        if (scrollTID) {
          clearTimeout(scrollTID);
        }

        setScrollTID(
          setTimeout(() => {
            setIsScrolling(false);
          }, 1000)
        );
      }
    }, 1000);

    throttledFunction();
  };

  const handleScroll = (event) => {
    if (mobileScorll) {
      return;
    }

    const element = document.querySelector('.wrapper');

    if (
      selected === sliders.length - 1 &&
      !element?.classList?.contains('no-scroll')
    ) {
      return;
    }

    if (selected === sliders.length - 1) {
      element?.classList?.remove('no-scroll');
      return;
    }

    if (isInViewport(document.getElementById('slider'))) {
      setTimeout(() => {
        element?.classList?.add('no-scroll');
      }, 100);
    } else {
      setTimeout(() => {
        element?.classList?.remove('no-scroll');
      }, 100);
    }
  };

  function isInViewport(element) {
    if (!element) {
      return;
    }
    // Get the bounding client rectangle position in the viewport
    var bounding = element.getBoundingClientRect();

    // Checking part. Here the code checks if it's *fully* visible
    // Edit this part if you just want a partial visibility
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
      startX = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      if (!startY || !startX) return;

      const diffY = e.changedTouches[0].clientY - startY;
      const diffX = e.changedTouches[0].clientX - startX;

      if (diffY < -20 && diffX < 20 && selected === sliders.length - 1) {
        // if (isInViewport(document.getElementById('slider'))) {
        document.querySelector('.faq').scrollIntoView(true);
        // }
      }
    };

    const scrollContainer = document.querySelector('.wrapper');
    scrollContainer.addEventListener('touchstart', handleTouchStart);
    scrollContainer.addEventListener('touchend', handleTouchEnd);
    scrollContainer.addEventListener('scroll', handleScroll, false);

    const intervalId = setInterval(handleScroll, 100);

    return () => {
      clearInterval(intervalId);
      scrollContainer.removeEventListener('touchstart', handleTouchStart);
      scrollContainer.removeEventListener('touchend', handleTouchEnd);
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [selected]);

  useEffect(() => {
    if (mobileScorll) {
      let el = document.createElement('style');
      el.type = 'text/css';
      el.innerText = '::-webkit-scrollbar{width:0px}';
      document.head.appendChild(el);
      return () => {
        el.remove();
      };
    }

    if (isInViewport(document.getElementById('slider'))) {
      document
        .getElementsByClassName('wrapper')[0]
        ?.classList?.add('no-scroll');
    }
    return () => {
      document
        .getElementsByClassName('wrapper')[0]
        ?.classList?.remove('no-scroll');
    };
  }, []);

  const generateAltTag = (sliderTitle) => {
    if (sliderTitle === 'Zavese i Draperije') {
      return 'Zavese i Draperije';
    } else if (sliderTitle === 'Rimske zavese') {
      return 'Rimske zavese';
    } else if (sliderTitle === 'Drveni venecijaneri') {
      return 'Drveni venecijaneri';
    } else if (sliderTitle === 'Zebra zavese') {
      return 'Zebra zavese';
    } else if (sliderTitle === 'Rolo zavese') {
      return 'Rolo zavese';
    } else if (sliderTitle === 'Plise zavese') {
      return 'Plise zavese';
    } else if (sliderTitle === 'Zip sistemi') {
      return 'Zip sistemi';
    } else if (sliderTitle === 'Panelne zavese') {
      return 'Panelne zavese';
    } else if (sliderTitle === 'Komarnici') {
      return 'Komarnici';
    } else if (sliderTitle === 'Tapete') {
      return 'Tapete';
    }
  };

  const titleToProductIdMap = {
    'Rimske zavese': 'paketo-zavese',
    'Plise zavese': 'plise-zavese',
    Komarnici: 'plise-komarnici',
  };

  function getShopUrlForTitle(title) {
    const productId = titleToProductIdMap[title];
    if (!productId) {
      return '/shop';
    }
    return `/shop?product=${productId}`;
  }

  return (
    <div id="slider" style={{ position: 'relative' }} onWheel={handleWheel}>
      <Carousel
        className={'scroll-snap'}
        dynamicHeight
        // infiniteLoop
        interval={10000000} //to ignore bug it his lib version
        emulateTouch={false}
        showStatus={false}
        autoPlay={false}
        showThumbs={false}
        showIndicators={false}
        showArrows={false}
        width={'100%'}
        shopOnHover={false}
        onChange={setSelected}
        selectedItem={selected}
      >
        {sliders.map((slider, index) => (
          <Section
            className={'no-zoom'}
            key={index}
            content={
              <SectionContent
                sliderKnobs={
                  <SliderKnobs sliders={sliders.length} selected={selected} />
                }
                title={slider.title}
                titleVariant={window.innerWidth <= 480 ? 'slider' : 'vertical'}
                message={slider.message}
                actions={
                  <>
                    <div className="shopButtons">
                      <ButtonLink
                        type={'shop'}
                        onClick={(e) => {
                          window.location.href = getShopUrlForTitle(
                            slider.title
                          );
                        }}
                      >
                        SHOP
                      </ButtonLink>
                      <ButtonLink
                        type={'slider'}
                        onClick={(e) => {
                          e.preventDefault();
                          document.querySelector('.faq').scrollIntoView(true);
                        }}
                      >
                        Česta pitanja
                      </ButtonLink>
                    </div>
                  </>
                }
              />
            }
            bgMedia={
              <img
                src={slider.image}
                alt={generateAltTag(slider.title) ?? 'bgZavese'}
              />
            }
          />
        ))}
      </Carousel>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100svw',
          height: '100svh',
          pointerEvents: 'none',
        }}
      >
        {mobileScorll && selected === sliders.length - 1 && (
          <img
            style={{
              position: 'absolute',
              bottom: '15%',
              right: '40%',
              width: '60px',
            }}
            src={swipeUp}
          />
        )}
        {mobileScorll && selected === 0 && (
          <img
            style={{
              position: 'absolute',
              top: '40%',
              left: '80%',
              width: '60px',
            }}
            src={swipeLeft}
          />
        )}
      </div>
    </div>
  );
};

export default Slider;
