import React from 'react';
import './sectionContent.css';

const SectionContent = ({
  title,
  message,
  actions,
  titleVariant = 'horizontal',
  sliderKnobs,
  preTitle,
}) => {
  if (titleVariant === 'slider') {
    return (
      <div className="sectionContent">
        {sliderKnobs}
        <div className="sectionContentSlider">
          <div className="sectionTitleVertical">
            {preTitle && (
              <div className="sectionContentPreTitle">{preTitle}</div>
            )}
            <h1 className="sectionContentTitle">{title} </h1>

            <div className="sectionContentMessage">{message}</div>
          </div>

          {actions && (
            <div className="sectionContentActions">
              <>{actions}</>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="sectionContent">
      {titleVariant === 'horizontal' ? (
        <>
          {preTitle && <div className="sectionContentPreTitle">{preTitle}</div>}
          <h1 className="sectionContentTitle">{title} </h1>

          <div className="sectionContentMessage">{message}</div>
        </>
      ) : (
        <div className="sectionTitleVertical">
          {preTitle && <div className="sectionContentPreTitle">{preTitle}</div>}
          <h1 className="sectionContentTitle">{title} </h1>

          <div className="sectionContentMessage">{message}</div>
        </div>
      )}

      {actions && (
        <div className="sectionContentActions">
          <>
            {sliderKnobs}
            {actions}
          </>
        </div>
      )}
    </div>
  );
};

export default SectionContent;
