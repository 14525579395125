import React, { useState, useEffect, useRef } from 'react';
import './CareersPage.css';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, FormControl, FormLabel } from '@mui/material';

const CareersPage = () => {
  const [cvFile, setCvFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  // Funkcija za okidanje klik eventa na skrivenom inputu
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCvFileChange = (e) => {
    setCvFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('fullName', e.target.fullName.value);
    formData.append('email', e.target.email.value);
    formData.append('phone', e.target.phone.value);
    formData.append('birthDate', e.target.birthDate.value);
    formData.append('city', e.target.city.value);
    formData.append('convicted', e.target.convicted.value);
    formData.append('education', e.target.education.value);
    formData.append('experience', e.target.experience.value);
    if (cvFile) {
      formData.append('cv', cvFile);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/careerApplications`,
        {
          method: 'POST',
          body: formData,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        console.log('Podaci uspešno poslati:', responseData);
        alert('Prijava uspešno poslata!');
      } else {
        console.error('Greška pri slanju:', responseData);
        alert(
          'Došlo je do greške pri slanju prijave. Ukoliko ne uspete iz ponovnog pokušaja, kontaktirajte nas na info@dalux.rs'
        );
      }
    } catch (error) {
      console.error('Došlo je do greške pri slanju podataka:', error);
      alert(
        'Došlo je do greške pri slanju prijave. Ukoliko ne uspete iz ponovnog pokušaja, kontaktirajte nas na info@dalux.rs'
      );
    }
  };

  return (
    <div className="careersContainer">
      <h1 className="careersTitle">Zaposlenje</h1>
      <div className="textBlack">
        <p>DaLux je preduzeće koje posluje preko 30 godina.</p>
        <span>
          Naša osnovna delatnost je opremanje enterijera zavesama, draperijama,
          garnišnama i sistemima zaštite od sunca.{' '}
        </span>
      </div>
      <div className="textBlack">
        <p>
          Ukoliko tražite posao, komunikativni ste, želite da se pridružite
          našem timu,
        </p>
        <strong>
          pošaljite Vaš CV na info@dalux.rs ili se prijavite putem forme:
        </strong>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <FormControl fullWidth className="formControl">
          <TextField
            id="fullName"
            label="Ime i prezime"
            variant="outlined"
            required
            fullWidth
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="email"
            label="Email adresa"
            variant="outlined"
            type="email"
            required
            fullWidth
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="phone"
            label="Broj telefona"
            variant="outlined"
            type="text"
            required
            fullWidth
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="birthDate"
            label="Datum rođenja (dan.mesec.godina)"
            variant="outlined"
            type="text"
            required
            fullWidth
            margin="normal"
            inputProps={{ pattern: '\\d{1-2}\\.\\d{1-2}\\.\\d{4}' }}
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="city"
            label="Grad"
            variant="outlined"
            type="text"
            required
            fullWidth
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="convicted"
            label="Da li ste osuđivani?"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            required
            fullWidth
            margin="normal"
          >
            <option value=""></option>
            <option value="da">Da</option>
            <option value="ne">Ne</option>
          </TextField>
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="education"
            label="Obrazovanje (nije potrebno ako je u CV)"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <TextField
            id="experience"
            label="Radno iskustvo (nije potrebno ako je u CV)"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </FormControl>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <FormControl fullWidth component="fieldset" variant="standard">
            <FormLabel component="legend" style={{ marginBottom: '10px' }}>
              Postavite svoj CV:
            </FormLabel>
            <input
              ref={fileInputRef}
              type="file"
              accept=".pdf,.doc,.docx,image/png,image/jpeg"
              onChange={handleCvFileChange}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              onClick={handleButtonClick}
              style={{
                alignSelf: 'flex-start', // Ovo će poravnati dugme levo
              }}
            >
              Odaberite fajl
            </Button>
            {fileName && (
              <div
                className="textBlack"
                style={{ marginTop: 8, textAlign: 'left' }}
              >
                {fileName}
              </div>
            )}
          </FormControl>
        </div>

        <Button
          type="submit"
          variant="contained"
          endIcon={<SendIcon />}
          style={{
            maxWidth: '200px',
            alignSelf: 'center',
            marginTop: '30px',
            backgroundColor: '#19d22e',
          }}
        >
          Pošalji prijavu
        </Button>
      </form>
      <div className="textBlack" style={{ marginTop: '30px' }}>
        <p>
          U procesu selekcije kandidati neće biti neposredno niti posredno
          diskriminisani u odnosu na zdravstveno stanje, odnosno invalidnost,
          pol, boju kože, starost, nacionalnu pripadnost, veroispovest, bračni
          status, seksualno ili političko opredeljenje, članstvo u različitim
          organizacijama ili neko drugo lično svojstvo.
        </p>
        <p>
          <strong>Napomena: </strong>Slanjem prijave, kandidat daje pristanak da
          kompanija njegove lične podatke obrađuje u postupku selekcije u skladu
          sa Zakonom o zaštiti podataka o ličnosti i da isti budu sastavni deo
          baze kandidata kompanije.
        </p>
      </div>
    </div>
  );
};

export default CareersPage;
