import React, { useLayoutEffect, useState } from 'react';
import BgVideo from '../../assets/dalux_nov.mp4';
import BgVideoMobile from '../../assets/dalux_mobile.mp4';
import './hero.css';
import Section from '../section/Section';
import SectionContent from '../sectionContent/SectionContent';
import AboutVideo from '../../assets/heroAnimation/oNamaVideo.png';
import AboutText from '../../assets/heroAnimation/oNamaText.png';
import Shop from '../../assets/heroAnimation/shopKorpa.png';
import ShopText from '../../assets/heroAnimation/shopText.png';

const Hero = () => {
  const [videoSource, setVideoSource] = useState(BgVideo);
  const [message, setMessage] = useState(
    'Naš tim stručnjaka za dizajn i proizvodnju pažljivo sarađuje sa Vama kako bi stvorio savršeno rešenje za Vaš prostor. Zavese po meri će se savršeno uklopiti i pružiti Vam ne samo estetsku, već i funkcionalnu vrednost. Zajedno gradimo ambijent koji odražava Vašu jedinstvenost, dopuštajući da Vaš prostor govori priče o Vama.'
  );

  useLayoutEffect(() => {
    const setResponsiveness = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setVideoSource(BgVideoMobile);
        setMessage(
          'Naš tim stručnjaka za dizajn i proizvodnju pažljivo sarađuje sa Vama kako bi stvorio savršeno rešenje za Vaš prostor. Zavese po meri će se savršeno uklopiti i pružiti Vam ne samo estetsku, već i funkcionalnu vrednost.'
        ); // Poruka za mobilne uređaje bez poslednje rečenice
      } else {
        setVideoSource(BgVideo);
        setMessage(
          'Naš tim stručnjaka za dizajn i proizvodnju pažljivo sarađuje sa Vama kako bi stvorio savršeno rešenje za Vaš prostor. Zavese po meri će se savršeno uklopiti i pružiti Vam ne samo estetsku, već i funkcionalnu vrednost. Zajedno gradimo ambijent koji odražava Vašu jedinstvenost, dopuštajući da Vaš prostor govori priče o Vama.'
        ); // Poruka za desktop uređaje
      }
    };

    setResponsiveness();
    window.addEventListener('resize', setResponsiveness);

    return () => {
      window.removeEventListener('resize', setResponsiveness);
    };
  }, []);

  return (
    <Section
      className="hero"
      content={
        <SectionContent
          titleVariant="vertical"
          title={
            <span className="uniqueTitleFont">
              Oplemenite svoj životni prostor
            </span>
          }
          message={message}
          actions={
            <>
              <a href="/shop">
                <img src={Shop} alt="shop" />
                <img className="rotateImg" src={ShopText} alt="shopText" />
              </a>
            </>
          }
        />
      }
      bgMedia={
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className="bg-video"
        >
          <source src={videoSource} type="video/mp4" />
        </video>
      }
    />
  );
};

export default Hero;
