import React, { useState, useEffect } from 'react';
import { StyledBox, StyledStack } from '../../containers/ShopNew/Shop.styled';

import { products } from '../../helpers/products';
export const Products = ({ setProduct }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const updateCurrentTime = () => {
    setCurrentTime(new Date());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateCurrentTime();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTimeLeft = (expirationTime) => {
    const difference = expirationTime - currentTime;
    let hours = Math.floor(difference / 3600000);
    let minutes = Math.floor((difference % 3600000) / 60000);
    let seconds = Math.floor((difference % 60000) / 1000);

    return {
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(
        calculateTimeLeft(new Date().getTime() + 48 * 60 * 60 * 1000)
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <StyledBox>
        {products.map((product) => {
          const expirationTime =
            product.title === 'plise-komarnici'
              ? new Date().getTime() + 48 * 60 * 60 * 1000
              : null;

          return (
            <StyledStack
              key={product.id}
              onClick={() => {
                setProduct(product);
                window.scrollTo(0, 0);
              }}
            >
              <img src={product.img} alt={product.title} />
              {expirationTime && (
                <p>{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</p>
              )}
              <p>
                {product.title}
                {product.subtitle && (
                  <>
                    <br />
                    <p className="bez">{product.subtitle}</p>
                  </>
                )}
              </p>
            </StyledStack>
          );
        })}
      </StyledBox>
    </>
  );
};
