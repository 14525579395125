import React from 'react';
import './buttonLink.css';

const ButtonLink = ({ href, children, onClick, type }) => {
  if (type === 'slider') {
    return (
      <a href={href} className="buttonLink sliderLink" onClick={onClick}>
        {children}
      </a>
    );
  }

  if (type === 'shop') {
    return (
      <a href={href} className="buttonLink shopButton" onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <a className="buttonLink" onClick={onClick} href={href}>
      {children}
    </a>
  );
};

export default ButtonLink;
