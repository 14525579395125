import { Box } from '@mui/material';
import styled from 'styled-components';

export const ShopContainer = styled.div`
  background-color: ${({ activeStep }) =>
    activeStep === 0 ? '#f4f4f4' : 'transparent'}; // Ovo dodajete
  min-height: 100svh;
  color: black;
  padding-top: 90px;

  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    padding-top: 100px;
  }
`;

export const ShopWrapper = styled.div`
  // max-width: 1200px;

  max-width: 82%;
  margin: 0 auto;
  width: 100%;
  padding-top: 40px;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-align: left;
  position: relative;

  .error {
    color: #ff0000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
  }

  .bez {
    font-size: 14px;
    text-align: center;
    color: grey;
  }

  & button {
    margin-top: 32px;
    outline: none;
    border: none;
    background-color: #89ff7e;
    border-radius: 10px;
    width: 144px;
    height: 33px;
    cursor: pointer;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      display: block;
      background-image: url('/images/cart-black.svg');
      background-size: cover;
      width: 20px;
      height: 17px;
    }
  }

  .btns {
    //position: absolute;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 50%;
    //transform: translate(-50%);

    .prev {
      background-color: unset;
      width: 94px;
      justify-content: center;
      color: #000;
      font-family: 'Inter';
      font-size: 15px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
    }

    .next {
      background-color: #ededed;
      width: 94px;
      border-radius: 0;
      justify-content: center;
      color: #000;
      font-family: 'Inter';
      font-size: 15px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 10px;

    .main_wrapper {
      padding-bottom: ${({ activeStep }) => activeStep === 2 && '0px'};
    }

    .error {
      position: absolute;
      bottom: ${({ activeStep }) => (activeStep === 2 ? '40px' : '0px')};
      right: 16px;
    }

    .inputs {
      flex-direction: column;
    }

    .btns_wrapper {
      position: relative;

      .btns {
        bottom: ${({ activeStep }) => (activeStep === 2 ? '0' : '24px')};
        right: 16px;
        left: unset;
        transform: unset;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    .btns {
      // position: absolute;
      // bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      // left: 50%;
      // transform: translate(-50%);
      .prev {
        width: 120px;
        font-size: 20px;
      }

      .next {
        width: 120px;
        font-size: 20px;
      }
    }
  }
`;

export const StyledBoxOne = styled(Box)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 100px;

  .description {
    & h6 {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      margin: 0;
    }

    & p {
      color: rgba(0, 0, 0, 0.56);
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .color {
    & p {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin: 0;
    }
  }

  .dimensions {
    margin-top: 48px;

    & p {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin: 0;
      margin-bottom: 24px;
    }

    & span {
      color: rgba(0, 133, 255, 0.56);
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
    }

    .inputs {
      display: flex;
      gap: 32px;
      & input {
        outline: none;
        border: none;
        background-color: #ededed;
        width: 95px;
        height: 40px;
        padding: 0 12px;
      }

      & label {
        color: #000;
        font-family: 'Inter';
        font-size: 10px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        margin-bottom: 4px;
      }

      & select {
        outline: none;
        border: none;
        background-color: #ededed;
        width: 114px;
        height: 40px;
        text-align: left;
        border-right: 12px solid transparent;
      }
    }
  }

  .dimensionsColorAround {
    margin-top: 30px;

    & p {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin: 0;
      margin-bottom: 24px;
    }

    & span {
      color: rgba(0, 133, 255, 0.56);
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
    }

    .inputs {
      display: flex;
      gap: 32px;
      & input {
        outline: none;
        border: none;
        background-color: #ededed;
        width: 95px;
        height: 40px;
        padding: 0 12px;
      }

      & label {
        color: #000;
        font-family: 'Inter';
        font-size: 10px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        margin-bottom: 4px;
      }

      & select {
        outline: none;
        border: none;
        background-color: #ededed;
        width: 114px;
        height: 40px;
        text-align: left;
        border-right: 12px solid transparent;
      }
    }
  }

  .inputsWithoutDimensions {
    margin-top: 10px;
    margin-bottom: 20px;

    & select {
      outline: none;
      border: none;
      background-color: #ededed;
      width: 200px; /* Promenite širinu ako je potrebno */
      height: 40px;
      padding: 0 12px;
      text-align: left;
      border-right: 12px solid transparent; /* Ovo možete prilagoditi ili ukloniti prema potrebi */
      /* Dodajte dodatne stilove ako je potrebno */
    }

    & label {
      color: #000;
      font-family: 'Inter';
      font-size: 10px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin-bottom: 4px;
    }
  }

  .price {
    margin-top: 48px;

    & p {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin: 0;
    }

    & span {
      margin-bottom: 32px;
    }
  }

  .priceColorAround {
    margin-top: 30px;

    & p {
      color: #000;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: normal;
      margin: 0;
    }

    & span {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1700px) {
    gap: unset;
  }
`;

export const Sidebar = styled.div`
  color: #fff;
  min-height: 100svh;
  width: calc(330px - 32px);
  background-color: #000;
  padding: 16px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'Inter';

  .item {
    height: 44px;
    width: 44px;
  }

  .total_price {
    color: #fff;
    font-family: DM Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 40px;
  }

  .add_item {
    margin-top: 40px;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    cursor: pointer;
  }

  & span {
    color: #d3d3d3;
  }

  .cart_icon {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 8px 0 24px 0;
  }

  @media screen and (min-width: 1400px) {
    // height: 100svh;
    overflow: auto;
    & p {
      font-size: 15px;
    }

    .total_price {
      font-size: 15px;
    }

    .item {
      height: 56px;
      width: 56px;
    }
  }

  .proceed_to_checkout {
    background-color: #fff; /* Crna pozadina */
    color: #000; /* Beli tekst */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px; /* Razmak od ukupne cene */
    width: 100%; /* Širina da se prilagodi kontejneru */
    text-align: center; /* Tekst centriran */
    transition: background-color 0.3s ease;
  }

  .proceed_to_checkout:hover {
    background-color: #444; /* Promena boje na hover */
  }
`;

export const Circle = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 100%;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.isActive && `2px solid #FFF`};
  outline: ${(props) => props.isActive && `2px solid ${props.selectedColor}`};

  @media screen and (max-width: 600px) {
    height: 25px;
    width: 25px;
  }
`;

export const ChannelType = styled.p`
  min-width: 106px;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  color: rgb(45, 41, 38);
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  padding: 8px;
  line-height: 24px;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: nowrap;
  margin: 2px;
  border: none;
  box-shadow: rgb(45, 41, 38) 0px 0px 0px 1.5px;

  border: ${(props) =>
    props.isActive ? '1px solid rgb(45, 41, 38)' : '0px solid rgb(45, 41, 38)'};
  font-weight: ${(props) => (props.isActive ? '600' : '300')};
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
  gap: 80px;

  & div:nth-of-type(1) {
    height: 550px;
  }

  @media screen and (max-width: 1400px) {
    flex-direction: column;
    padding: 0 16px;
    gap: 24px;
    margin-top: 44px;
    padding-bottom: 24px;

    & div:nth-of-type(1) {
      height: unset;
    }
  }

  @media screen and (min-width: 1401px) {
    gap: 20px;
    flex-wrap: wrap;

    & div:nth-of-type(1) {
      height: 450px;
    }
  }

  @media screen and (min-width: 1700px) {
    & div:nth-of-type(1) {
      height: 550px;
    }
  }
`;

export const PopupStyle = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const OverlayStyle = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const StyledStack = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  min-height: 450px;
  background-color: white;

  border: 1px solid lightgray; // Svetlosiva linija oko komponente
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); // Mala senka
  // padding: 20px; // Dodati prostor unutar okvira, ako je potrebno
  // margin: 20px; // Dodati prostor izvan okvira, ako je potrebno
  transition: box-shadow 0.3s ease; // Tranzicija za hover efekat
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); // Jača senka na hover
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    height: 152px;
    background-color: white;
    width: calc(100% - 32px);
    border-radius: 20px;
    padding: 16px;
    gap: 20px;
    text-align: left;
    min-height: unset;

    & p {
      font-size: 18px;
    }

    & img {
      height: 150px;
      width: 148px;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    gap: 0px !important;
  }

  @media screen and (min-width: 1400px) {
    height: 450px;
    // gap: 38px;
    & img {
      height: 350px;
      width: 350px;
    }

    & p {
      font-size: 30px;
    }

    .bez {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 1700px) {
    height: 550px;

    & img {
      height: 450px;
      width: 450px;
    }

    & p {
      font-size: 36px;
    }
  }
`;

export const StepTwoWrapper = styled.div`
  max-width: 390px;
  margin: 0 auto;

  /* Osnovni stil za dugme */
  .button-payment {
    background-color: #4caf50; /* Zelena boja */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Promena kursora na pointer */
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Lagani shadow */
    transition: all 0.3s ease; /* Glatka tranzicija za hover efekte */
  }

  /* Hover efekat za dugme */
  .button-payment:hover {
    background-color: #45a049; /* Tamnija zelena boja na hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Veći shadow na hover */
  }

  .content {
    font-size: 16px;
  }

  .list {
    margin-top: 20px;
    max-height: 150px;
    width: 100%;
    overflow-y: auto;
    padding-right: 10px;
    font-size: 15px;
    justify-content: center;
    gap: 30px;
  }

  .prev {
    background-color: #ededed;
    justify-content: center;
    color: #000;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    border-radius: 0px;
    height: unset;
    width: unset;
    padding: 10px;
  }

  .next {
    background-color: #ededed;

    border-radius: 0;
    justify-content: center;
    color: #000;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    height: unset;
    width: unset;
    padding: 10px;
  }

  & input {
    outline: none;
    border: none;
    background-color: #ededed;
    width: 155px;
    height: 40px;
    padding: 0 12px;
  }

  .numbers {
    width: 60px;
    padding: 0 12px;
  }

  .mobile {
    background: rgba(255, 0, 0, 0.13);
    position: relative;
  }

  .required {
    position: absolute;
    right: 4px;
    top: 20px;
    color: #f00;
    font-family: 'Inter';
    font-size: 7px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
  }

  & label {
    color: #000;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    margin-bottom: 4px;
    text-align: left;
  }

  & textarea {
    overflow: auto;
    width: 390px;
    height: 50px;
    outline: none;
    border: none;
    background-color: #ededed;
    resize: none;
    padding: 10px 12px;
  }

  @media screen and (max-width: 480px) {
    & input {
      width: 120px;
    }

    & textarea {
      width: 290px !important;
    }
  }

  @media screen and (max-width: 600px) {
    //height: 100%;
    padding: 0 16px;

    & textarea {
      width: 358px;
    }
  }

  @media screen and (min-width: 1400px) {
    h6 {
      font-size: 22px;
      text-align: left;
    }

    & label {
      font-size: 11px;
    }
  }
`;
