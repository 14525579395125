import React from 'react';
import { Container } from '../../components';
import photo1 from '../../assets/blog/article4/image00002-855x500.webp';
import photo2 from '../../assets/blog/article4/image00003-1024x576.webp';
import photo3 from '../../assets/blog/article4/image00004-1024x576.webp';
import photo4 from '../../assets/blog/article4/image00005-1024x576.webp';
import photo5 from '../../assets/blog/article4/image00006-1024x576.webp';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>
          Kako ukloniti neprijatne mirise sa zavesa i draperija - DaLux.rs
        </title>
        <meta
          name="title"
          content="Kako ukloniti neprijatne mirise sa zavesa i draperija - DaLux.rs"
        />
        <meta
          name="description"
          content="Saznajte kako da uklonite neprijatne mirise sa zavesa i draperija koristeći sodu bikarbonu, votku, sunce i svež vazduh, ili sirće."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/blog/kako-ukloniti-neprijatne-mirise-sa-zavesa-i-draperija"
        />

        <meta
          property="og:title"
          content="Kako ukloniti neprijatne mirise sa zavesa i draperija - DaLux.rs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/blog/kako-ukloniti-neprijatne-mirise-sa-zavesa-i-draperija"
        />
        <meta property="og:site_name" content="DaLux.rs" />
        <meta
          property="og:description"
          content="Saznajte kako da uklonite neprijatne mirise sa zavesa i draperija koristeći sodu bikarbonu, votku, sunce i svež vazduh, ili sirće."
        />
        <meta property="og:locale" content="sr_RS" />
      </Helmet>

      <Container>
        <div
          style={{
            color: 'black',
            flexDirection: 'column',
            minHeight: '800px',
            paddingTop: '120px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            className={'articleBack'}
            onClick={() => {
              window.location = '/blog';
            }}
          >
            {'< Nazad na Blog'}
          </div>

          <div className={'articleTitle'}>
            <h1>
              Kako da odaberete odgovarajuću boju zavesa i draperija za vašu
              sobu
            </h1>
            {/* <small className={'articleDate'}>18. novembar, 2022.</small> */}
          </div>

          <p className={'articleBody'}>
            <img src={photo1} />
            Koliko puta smo ušli u neki lepo dekorisani prostor koji je imao
            draperije koje se “osećaju”. Ova činjenica kvari opšti utisak i
            odbija ljude. Ovo se pogotovo odnosi na prostore sa većim procentom
            vlage. Draperije poprimaju specifičan i neprijatan miris posle nekog
            vremena. Jedan od uzročnika svakako može biti i buđ, koja negativno
            utiče na zdravlje. Koji je najbolji način da se oslobodite
            neprijatnih mirisa sa draperija? U nastavku sledi nekoliko saveta
            koji vam mogu pomoći.
            <h3>Soda bikarbona</h3>
            Mogućnosti soda bikarbone u pogledu otklanjanja neprijatnih mirisa
            su neverovatne. Isto tako ima mogućnost uklanjanja neprijatnih
            mirisa sa draperija. Ono što treba da uradite je da draperiju
            stavite u veliku vreću za smeće, dodate celu kesicu (ili dve) soda
            bikarbone i zatim je ostavite na nekoliko sati. Povremeno protresite
            vreću sa draperijom. Nakon toga izvadite draperiju i otresite sodu
            bikarbonu sa nje. Nakon toga bi trebalo da primetite znatnu razliku.
            <img src={photo2} />
            <h3>Verovali ili ne - Votka</h3>
            Votka je jedno od najboljih rešenja za uklanjanje neprijatnih mirisa
            sa draperija. Bilo koja jeftina votka može da posluži za ovu svrhu.
            Sve što treba da uradite je da razredite votku sa malo vode i da je
            sipate u bocu sa prskalicom, a zatim dobro naprskate draperije.
            Dajte joj samo par minuta da da se osuši i vaše draperije će biti
            kao nove.
            <img src={photo3} />
            <h3>Sunce i svež vazduh</h3>
            Kao što ljudima treba svež vazduh i sunce i vašim draperijama će
            biti od značajne koristi. Ideja je jasna i jednostavna. Nakon što
            operete draperije u veš mašini, okačite ih da se osuše na otvorenom
            prostoru kojoe obiluje Suncem i svežim vazduhom. Naravno, ne treba
            preterivati sa izloženošću Suncem kako ne bi došlo do oštećenja boje
            draperije.
            <img src={photo4} />
            <h3>Sirće</h3>
            Sirće, takođe, može biti odlično oružje protiv neprijatnih mirisa
            vaših draperija. Kako se koristi? Razredite sirće sa malo vode i
            isprskajte mali deo draperije kako biste testirali reakciju
            materijala. Ukoliko nema promena naprskajte celu draperiju. Nakon
            toga, ostavite je na nekoliko sati da se osuši na svežem vazduhu.
            Ovaj postupak bi trebalo da osveži vaše draperije.
            <img src={photo5} />
          </p>
        </div>
      </Container>
    </>
  );
};
