import { Box, Stack } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { StepTwoWrapper } from '../../containers/ShopNew/Shop.styled';
import { CartContext } from '../../CartContext';

export const UserInfo = ({}) => {
  const { userInfo, setUserInfo } = useContext(CartContext);

  const [name, setName] = useState(userInfo?.name || '');
  const [surname, setSurname] = useState(userInfo?.surname || '');
  const [street, setStreet] = useState(userInfo?.street || '');
  const [houseNo, setHouseNo] = useState(userInfo?.houseNo || '');
  const [postNo, setPostNo] = useState(userInfo?.postNo || '');
  const [city, setCity] = useState(userInfo?.city || '');
  const [phone, setPhone] = useState(userInfo?.phone || '');
  const [info, setInfo] = useState(userInfo?.info || '');
  const [apartmentNo, setApartmentNo] = useState(userInfo?.apartmentNo || '');
  const [email, setEmail] = useState(userInfo?.email || '');

  const [valid, setValid] = useState([]);

  useEffect(() => {
    if (name && surname && street && postNo && phone && email && city) {
      setUserInfo({
        name,
        surname,
        street,
        postNo,
        city,
        phone,
        info,
        houseNo,
        apartmentNo,
        email,
      });
    } else {
      setUserInfo(null);
    }
  }, [
    name,
    surname,
    street,
    postNo,
    phone,
    email,
    apartmentNo,
    info,
    houseNo,
    city,
  ]);

  return (
    <StepTwoWrapper>
      <h6 style={{ marginBottom: '30px', textAlign: 'center' }}>
        Podaci za dostavu
      </h6>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Stack position="relative">
          <label htmlFor="ime">Ime *</label>
          <input
            type="text"
            name="ime"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
        </Stack>
        <Stack position="relative">
          <label htmlFor="prezime">Prezime *</label>
          <input
            type="text"
            name="prezime"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
        </Stack>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Stack position="relative" width="179px">
          <label htmlFor="ulica">Ulica *</label>
          <input
            type="text"
            name="ulica"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
          {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
        </Stack>
        <Box display="flex" justifyContent="space-between" width="154px">
          <Stack>
            <label htmlFor="houseNo">Kućni Broj</label>
            <input
              type="text"
              name="houseNo"
              className={`numbers`}
              value={houseNo}
              onChange={(e) => setHouseNo(e.target.value)}
            />
          </Stack>
          <Stack position="relative">
            <label htmlFor="apartmentNo">Broj stana</label>
            <input
              type="text"
              name="apartmentNo"
              className={`numbers`}
              value={apartmentNo}
              onChange={(e) => setApartmentNo(e.target.value)}
            />
            {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
          </Stack>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Stack>
          <label htmlFor="grad">Grad *</label>
          <input
            type="text"
            name="grad"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Stack>
        <Stack position="relative">
          <label htmlFor="postNo">Poštanski Broj *</label>
          <input
            type="text"
            name="postNo"
            value={postNo}
            onChange={(e) => setPostNo(e.target.value)}
          />
          {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
        </Stack>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Stack>
          <label htmlFor="email">Email adresa *</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Stack>
        <Stack position="relative">
          <label htmlFor="mobile">Broj Telefona *</label>
          <input
            type="tel"
            name="mobile"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {valid === false && <p className="required">OBAVEZNO POLJE *</p>}
        </Stack>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Stack>
          <label htmlFor="info">Dodatne Informacije</label>
          <textarea
            type="text"
            name="info"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
        </Stack>
      </Box>
    </StepTwoWrapper>
  );
};
