import React, { useState } from 'react';
import './faq.css';
import Container from '../container/Container';
import clsx from 'clsx';

const Faq = ({ questions, faqImg }) => {
  const [active, setActive] = useState(0);
  return (
    <div className="faq">
      <a name="faq" />
      <Container>
        <div className="questions">
          {questions?.map((questions, index) => (
            <div
              key={index}
              className={clsx('faqRow', { active: active === index })}
            >
              <div
                className="faqRowHeading"
                onClick={() => {
                  setActive(index);
                }}
              >
                <h2 className="faqRowTitle">{questions.title}</h2>
              </div>
              <div className="faqRowContent">{questions.message}</div>
            </div>
          ))}
        </div>
        <img className="faqImg" src={faqImg} alt="faq-img"></img>
      </Container>
    </div>
  );
};

export default Faq;
