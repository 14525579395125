import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { visuallyHidden } from '@mui/utils';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Broj porudzbine',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'Ime i prezime',
  },
  {
    id: 'total_price',
    numeric: false,
    disablePadding: false,
    label: 'Cena porudzbine',
  },
  {
    id: 'order_status',
    numeric: false,
    disablePadding: false,
    label: 'Status porudzbine',
  },
  {
    id: 'change_status',
    numeric: false,
    disablePadding: false,
    label: 'Promeni Status',
  },
  {
    id: 'check_details',
    numeric: false,
    disablePadding: false,
    label: 'Pogledaj Detalje',
  },
  {
    id: 'delete_order',
    numeric: false,
    disablePadding: false,
    label: 'Brisanje porudzbina',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              padding: '8px',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/clients-orders/${orderId}`
      );
      setOrderDetails(response.data);
      console.log(orderDetails);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const handleOrderDetailsClick = (orderId) => {
    fetchOrderDetails(orderId);
  };

  const handleOpenDeleteModal = (orderId) => {
    setDeleteOrderId(orderId);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteOrder = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/orders/${deleteOrderId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        if (response.data.success) {
          alert('Narudžbina je obrisana.');
          fetchOrders();
        } else {
          alert('Došlo je do greške pri brisanju narudžbine.');
        }
      })
      .catch((error) => {
        console.error('Greška:', error);
        alert('Došlo je do greške pri komunikaciji sa serverom.');
      });
    handleCloseDeleteModal();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMenuClick = (event, unique_id) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrderId(unique_id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = async (orderId, newStatus) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
        {
          newStatus,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          // Ažurirajte UI
          alert('Status narudžbine je ažuriran.');
          fetchOrders();
        } else {
          // Greška
          alert('Došlo je do greške pri ažuriranju narudžbine.');
        }
      })
      .catch((error) => {
        console.error('Greška:', error);
        alert('Došlo je do greške pri komunikaciji sa serverom.');
      });
    setAnchorEl(null);
  };

  const visibleRows = React.useMemo(
    () =>
      Array.isArray(orders)
        ? stableSort(orders, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : [],
    [order, orderBy, page, rowsPerPage, orders]
  );

  if (loading) return <div>Loading...</div>;

  const modalStyle = {
    maxHeight: '80vh',
    position: 'absolute',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: 'black',
  };

  const modalStyleDelete = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: 'black',
    h2: {
      fontSize: '1.2rem',
      marginBottom: '20px',
    },
    button: {
      margin: '5px',
    },
  };

  const deleteButtonStyle = {
    backgroundColor: 'red',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '5px',
  };

  const dontDeleteButtonStyle = {
    backgroundColor: 'green',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '5px',
  };

  const parentButtonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '5px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  };

  const parentDeleteButtonStyle = {
    ...parentButtonStyle,
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: '#e60000',
    },
  };

  const zebraAndPaketoProducts = [
    'zebra-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'paketo-zavese',
  ];

  const colorAroundProducts = [
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'plise-zavese',
  ];

  const hooksProducts = ['kukice-okrugle', 'kukice-dijamantske'];

  const noHeightProducts = ['garnisne', 'kukice-okrugle', 'kukice-dijamantske'];

  const formatDate = (isoDate) => {
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const date = new Date(isoDate);
    return date.toLocaleDateString('sr-RS', options);
  };

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '12vh',
        paddingX: '5%',
      }}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={orders.length}
            />
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      {row.first_name} {row.last_name}
                    </TableCell>
                    <TableCell>{row.total_price}</TableCell>
                    <TableCell>{row.order_status}</TableCell>
                    <TableCell>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={(event) =>
                          handleMenuClick(event, row.unique_id)
                        }
                        endIcon={<ArrowDropDownIcon />}
                        sx={{
                          fontSize: '0.85em',
                          padding: '4px 8px',
                        }}
                      >
                        Promeni status
                      </Button>
                      <Menu
                        id="status-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          onClick={() => {
                            handleStatusChange(
                              selectedOrderId,
                              'Neprocesirana'
                            );
                          }}
                        >
                          Neprocesirana
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleStatusChange(selectedOrderId, 'U izradi')
                          }
                        >
                          U izradi
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleStatusChange(selectedOrderId, 'Poslata')
                          }
                        >
                          Poslata
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleStatusChange(selectedOrderId, 'Primljena')
                          }
                        >
                          Primljena
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell>
                      {
                        <button
                          style={parentButtonStyle}
                          onClick={() => handleOrderDetailsClick(row.unique_id)}
                        >
                          Detalji porudžbine
                        </button>
                      }
                    </TableCell>
                    <TableCell>
                      <button
                        style={parentDeleteButtonStyle}
                        onClick={() => handleOpenDeleteModal(row.unique_id)}
                      >
                        Obriši porudžbinu
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Broj porudzbina po stranici"
          labelDisplayedRows={({ from, to, count }) =>
            `Prikazuje se ${from}-${to} porudžbina od ${count}`
          }
        />
      </Paper>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={modalStyle}>
          <h2>Detalji Porudžbine</h2>
          <br></br>
          {orderDetails && (
            <>
              <p>
                ID Porudzbine: <br></br>
                {orderDetails.unique_id}{' '}
                <a
                  href={`https://www.dalux.rs/shop/order/${orderDetails.unique_id}`}
                  target="_blank"
                  style={{ color: 'blue' }}
                >
                  Link ka porudžbini
                </a>
              </p>
              <p>
                Vreme kreiranja porudžbine: <br></br>
                {orderDetails && formatDate(orderDetails.created_at)}h
              </p>
            </>
          )}
          <br></br>
          <h2>Podaci o mušteriji</h2>
          {orderDetails && (
            <>
              <p>
                Ime i prezime: {orderDetails.first_name}{' '}
                {orderDetails.last_name}
              </p>
              <p>Broj telefona: {orderDetails.phone_number}</p>
              <p>Email adresa: {orderDetails.email}</p>
              <p>
                Ulica i broj: {orderDetails.street}{' '}
                {orderDetails.house_number ?? 'bb'}
              </p>
              <p>
                Grad i poštanski broj: {orderDetails.city},{' '}
                {orderDetails.postal_code}
              </p>
              <p>
                Način plaćanja:{' '}
                {orderDetails.payment_type === 'karticno'
                  ? 'Karticom'
                  : 'Pouzecem'}
              </p>
              <p>
                Status uplate:{' '}
                {orderDetails.payment_status === 'Placeno'
                  ? 'Placeno'
                  : 'Nije uplaceno'}{' '}
              </p>
              <p>Ukupna cena: {orderDetails.total_price} RSD</p>
              {orderDetails.additional_info && (
                <p>Dodatne informacije: {orderDetails.additional_info}</p>
              )}
              {orderDetails.items && (
                <div>
                  <br></br>
                  <h3>Proizvodi:</h3>
                  {orderDetails.items.map((item, index) => (
                    <div key={index}>
                      <strong>
                        Proizvod:{' '}
                        {(() => {
                          switch (item.product_name) {
                            case 'plise-komarnici':
                              return 'Komarnik';
                            case 'plise-zavese':
                              return 'Plise zavese';
                            case 'zebra-zavese':
                              return 'Zebra zavese';
                            case 'zebra-sa-maskom':
                              return 'Zebra sa maskom';
                            case 'zebra-bez-maske':
                              return 'Zebra bez maske';
                            case 'kukice-okrugle':
                              return 'Kukice (okrugle)';
                            case 'kukice-dijamantske':
                              return 'Kukice (dijamantske)';
                            case 'garnisne':
                              return 'Garnišne';
                            case 'paketo-zavese':
                              return 'Paketo zavese';
                            default:
                              return 'Greska kod imena proizvoda, kontaktirati Master mediu.';
                          }
                        })()}
                      </strong>
                      <p>
                        {item.product_name === 'garnisne'
                          ? 'Broj kanala: '
                          : 'Boja: '}
                        {item.color}
                      </p>
                      {item.product_name === 'plise-zavese' && (
                        <p>Propustljivost: {item.transparency}</p>
                      )}
                      {colorAroundProducts.includes(item.product_name) && (
                        <p>Boja profila: {item.colorAround}</p>
                      )}
                      {!hooksProducts.includes(item.product_name) && (
                        <p>Širina: {item.width} cm</p>
                      )}
                      {!noHeightProducts.includes(item.product_name) && (
                        <p>Visina: {item.height} cm</p>
                      )}
                      {zebraAndPaketoProducts.includes(item.product_name) && (
                        <p>Orijentacija lanca: {item.side}</p>
                      )}
                      <p>Količina: {item.quantity}</p>
                      <p>Cena: {item.price} RSD</p>
                      <br></br>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Box>
      </Modal>
      <Modal open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <Box sx={modalStyleDelete}>
          <h2>Da li ste sigurni da želite da obrišete porudžbinu?</h2>
          <button style={deleteButtonStyle} onClick={handleDeleteOrder}>
            Da, obriši porudžbinu.
          </button>
          <button
            style={dontDeleteButtonStyle}
            onClick={handleCloseDeleteModal}
          >
            Ne, otkaži brisanje.
          </button>
        </Box>
      </Modal>
    </Box>
  );
}
