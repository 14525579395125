import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/komarnici/1.webp';
import SlideSecond from '../../assets/komarnici/2.webp';
import SlideThird from '../../assets/komarnici/3.webp';
import SlideFourth from '../../assets/komarnici/4.webp';

import SlideFirstPhone from '../../assets/komarnici_mobile/kom1.webp';
import SlideSecondPhone from '../../assets/komarnici_mobile/kom2.webp';
import SlideThirdPhone from '../../assets/komarnici_mobile/kom3.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Komarnici',
    message: 'Rešenje koje ne narušava izgled prozora i vrata.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Komarnici',
    message: 'Sprečavaju ulazak raznovrsnih insekata.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Komarnici',
    message: 'Omogućuju konstantan protok svežeg vazduha.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Komarnici',
    message: 'Zadržavaju alergene i prašinu spolja.',
    image: SlideFourth,
  },
];

const Komarnici = () => {
  return (
    <>
      <Helmet>
        <title>
          Komarnici - Idealna zaštita od komaraca i insekata - DaLux
        </title>
        <meta
          name="title"
          content="Komarnici - Idealna zaštita od komaraca i insekata - DaLux"
        />
        <meta
          name="description"
          content="Komarnici za prozore i komarnici za vrata, zaštitite se od komaraca i insekata. Fiksni, rolo i plise komarnici na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/komarnici" />

        <meta
          property="og:title"
          content="Komarnici - Idealna zaštita od komaraca i insekata - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/komarnici" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Komarnici za prozore i komarnici za vrata, zaštitite se od komaraca i insekata. Fiksni, rolo i plise komarnici na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.bc97dc97c3124908d001.webp"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Komarnici - Idealna zaštita od komaraca i insekata - DaLux",
      "url": "https://www.dalux.rs/komarnici",
      "description": "Komarnici za prozore i komarnici za vrata, zaštitite se od komaraca i insekata. Fiksni, rolo i plise komarnici na sajtu Dalux.rs.",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Komarnici",
            "item": "https://www.dalux.rs/komarnici"
          }
        ]
      }
    }
    `}
        </script>

        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Plise komarnici",
      "image": "https://www.dalux.rs/images/shop/komarnik/komarnik-Bela.webp",
      "description": "Komarnici sprečavaju ulazak insekata u Vaš dom. Omogoćavaju nesmetano provetravanje prostorije. Zadržavaju prašinu i alergene spolja. Rok isporuke: 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
      "url": "https://www.dalux.rs/shop?product=plise-komarnici",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "RSD",
        "price": "cena se može naći na sajtu",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "DaLux"
        }
      }
    }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koje su cene komarnika?',
            message:
              'Cene komarnika se kreću u rasponu od 3.000rsd-5310rsd po metru kvadratnom u zavisnosti koji sistem izaberete.',
          },
          {
            title: 'Koje vrste komarnika imamo u ponudi?',
            message: 'U ponudi imamo fiksne i plise komarnike.',
          },
          {
            title: 'Da li imate usluge izlaska na teren i montaže komarnika?',
            message:
              'Kao i za sve naše proizvode nudimo Vam i uslugu uzimanja tačnih dimenzija i montaže na teritoriji Beograda.',
          },
          {
            title: 'Koje boje su mrežice komarnika?',
            message:
              'Boja mreže komarnika je tamno siva. Za sve tri vrste: fiksne, rolo i plise komarnike.',
          },
          {
            title:
              'Kako da uzmemo mere za komarnike ukoliko imamo roletne na prozorima?',
            message:
              'Širinu i visinu je potrebno izmeriti koliko Vam dozvoli fasada. Nikada ne knapirajte dimenzije kako ne biste pogrešili u merenju.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za komarnike je 2-4 nedelje od momenta kada se uplati avans.',
          },
          {
            title: 'Šta su plise komarnici?',
            message:
              'Plise komarnici su vrsta komarnika za zaštitu od insekata koja se koristi na prozorima i vratima. Plise komarnici su karakteristični po svojoj plise (harmonika) strukturi koja omogućava lako sklapanje i rasklapanje.',
          },
          {
            title: 'U čemu je razlika između plise i drugih tipova komarnika?',
            message:
              'Glavna razlika između plise komarnika i ostalih tipova komarnika leži u njihovom jedinstvenom mehanizmu, koji omogućava laku upotrebu i montažu, a uz to i štedi prostor.',
          },
          {
            title: 'Koja je cena plise komarnika?',
            message:
              'Cena plise komarnika je prilagođena dimenzijama i zahtevima prostora, za tačnu cenu bi bilo najbolje da nas kontaktirate.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default Komarnici;
