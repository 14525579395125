import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/drveni/1.webp';
import SlideSecond from '../../assets/drveni/2.webp';
import SlideThird from '../../assets/drveni/3.webp';
import SlideFourth from '../../assets/drveni/4.webp';
import SlideFifth from '../../assets/drveni/5.webp';
import SlideSixth from '../../assets/drveni/6.webp';

import SlideFirstPhone from '../../assets/drveni_mobile/woodb1.webp';
import SlideSecondPhone from '../../assets/drveni_mobile/woodb2.webp';
import SlideThirdPhone from '../../assets/drveni_mobile/woodb3.webp';
import SlideFourthPhone from '../../assets/drveni_mobile/woodb4.webp';
import SlideFifthPhone from '../../assets/drveni_mobile/woodb5.webp';
import SlideSIxthPhone from '../../assets/drveni_mobile/woodb6.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;
const SLIDERS = [
  {
    title: 'Drveni venecijaneri',
    message: 'Pružaju toplinu i daju prirodan izgled prostoru.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Drveni venecijaneri',
    message: 'Utiču na kontrolu svetlosti i privatnosti.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Drveni venecijaneri',
    message: 'Izrađuju se prema vašim dimenzijama.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Drveni venecijaneri',
    message: 'Uklapaju se u stilove od klasičnog do modernog.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Drveni venecijaneri',
    message: 'Kvalitetni materijali otporni su na habanje.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
  {
    title: 'Drveni venecijaneri',
    message: 'Jednostavnim brisanjem vraćaju čistoću i sjaj.',
    image: desktop ? SlideSixth : SlideSIxthPhone,
  },
];

const DrveniVenecijaneri = () => {
  return (
    <>
      <Helmet>
        <title>Drveni venecijaneri - DaLux.rs</title>
        <meta name="title" content="Drveni venecijaneri - DaLux.rs" />
        <meta
          name="description"
          content="Za one koji traže jednostavnost i toplinu, drveni venecijaneri predstavljaju pravi izbor. Pogledajte ponudu na sajtu Dalux.rs"
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/drveni-venecijaneri" />

        <meta property="og:title" content="Drveni venecijaneri - DaLux.rs" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/drveni-venecijaneri"
        />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Za one koji traže jednostavnost i toplinu, drveni venecijaneri predstavljaju pravi izbor. Pogledajte ponudu na sajtu Dalux.rs"
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.c5777fd093cea71b9cba.webp"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Drveni venecijaneri - DaLux.rs",
        "url": "https://www.dalux.rs/drveni-venecijaneri",
        "description": "Za one koji traže jednostavnost i toplinu, drveni venecijaneri predstavljaju pravi izbor. Pogledajte ponudu na sajtu Dalux.rs",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Početna",
              "item": "https://www.dalux.rs"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Drveni venecijaneri",
              "item": "https://www.dalux.rs/drveni-venecijaneri"
            }
          ]
        }
      }
    `}
        </script>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Drveni venecijaneri",
        "image": "https://www.dalux.rs/static/media/1.c5777fd093cea71b9cba.webp",
        "description": "Za one koji traže jednostavnost i toplinu, drveni venecijaneri predstavljaju pravi izbor. Pogledajte ponudu na sajtu Dalux.rs",
        "brand": {
          "@type": "Brand",
          "name": "DaLux"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "RSD",
          "priceValidUntil": "2024-12-31",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "name": "DaLux"
          }
        }
      }
    `}
        </script>
      </Helmet>

      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koja je cena drvenih venecijanera?',
            message:
              'Cena drvenih venecijanera iznosi od 14.200 rsd/m2 - 21.500 rsd/m2 u zavisnosti od širine letvica.',
          },
          {
            title: 'Koja je maksimalna širina drvenih venecijanera?',
            message: 'Maksimalna širina za drvene venecijanere iznosi 2 metra.',
          },
          {
            title: 'Kako se održavaju drveni venecijaneri?',
            message:
              'Samo drvo se održava nežnim brisanjem vlažnom krpom, a povremeno možete iskoristiti i sredstvo za drvo, koje će doprineti sjaju i samoj čistoći venecijanera.',
          },
          {
            title: 'Da li vršite montažu drvenih venecijanera?',
            message:
              'Montažu drvenih venecijanera radi naš profesionalni tim na koji se uvek možete osloniti.',
          },
          {
            title: 'Koje boje drvenih venecijanera imate u ponudi?',
            message:
              'Boje drvenih venecijanera koje imamo u ponudi su bela, crna, braon i siva.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za drvene venecijanere je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default DrveniVenecijaneri;
