import React, { useEffect } from 'react';
import {
  ShopContainer,
  ShopWrapper,
  StepTwoWrapper,
} from '../ShopNew/Shop.styled';
import StepLabel from '@mui/material/StepLabel';
import { Stack, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CartItem } from '../../components/ShopNew/CartItem';
import axios from 'axios';

export const Order = ({}) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    fetchOrder(orderId);
  }, [orderId]);

  const fetchOrder = async () => {
    if (orderId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients-orders/${orderId}`
        );

        setOrder(response.data);
      } catch (error) {
        window.location.href = '/';
      }
    }
  };

  const steps = ['Izaberi', 'Specifikacije', 'Informacije', 'Plaćanje'];

  if (!order) return <></>;

  return (
    <div style={{ marginBottom: '20px' }}>
      <ShopContainer>
        <ShopWrapper activeStep={4}>
          <Stepper activeStep={4} alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <StepTwoWrapper>
            <div className={'content'}>
              <Stack alignItems="flex-start" width="100%">
                <Typography variant="h6" mt={4} alignSelf="center">
                  Stanje porudzbine:
                </Typography>
                <hr style={{ width: '100%', margin: '10px 0' }} />
                <Typography variant="h6" alignSelf="center">
                  {order.payment_type === 'pouzece' && (
                    <Typography variant="h6" alignSelf="center">
                      Porudzbina je primljena, plaćanje pouzećem.
                    </Typography>
                  )}
                  {order.payment_type === 'karticno' && (
                    <>
                      {order.payment_status === 'Odbijeno' && (
                        <Typography variant="body2" alignSelf="center">
                          Plaćanje nije uspešno, Vaš račun nije zadužen.
                          Najčešći uzrok je pogrešno unet broj kartice, datum
                          isteka ili sigurnosni kod. Pokušaje ponovo, u slučaju
                          uzastopnih greški pozovite Vašu banku.
                        </Typography>
                      )}

                      {order.payment_status === 'Neplaceno' && (
                        <Typography variant="body2" alignSelf="center">
                          Porudžbina nije plaćena.
                        </Typography>
                      )}

                      {order.payment_status === 'Placeno' && (
                        <Typography variant="body2" alignSelf="center">
                          Plaćanje uspešno, porudžbina je primljena.
                        </Typography>
                      )}

                      {(order.payment_status === 'Neplaceno' ||
                        order.payment_status === 'Odbijeno') && (
                        <center>
                          <form action={order.paymentGateway.url} method="post">
                            <input
                              type="hidden"
                              name="clientid"
                              value={order.paymentGateway.clientId}
                            />
                            <input
                              type="hidden"
                              name="storetype"
                              value="3d_pay_hosting"
                            />
                            <input
                              type="hidden"
                              name="hashAlgorithm"
                              value="ver2"
                            />
                            <input
                              type="hidden"
                              name="hash"
                              value={order.paymentGateway.hash}
                            />
                            <input
                              type="hidden"
                              name="trantype"
                              value={order.paymentGateway.transactionType}
                            />

                            <input
                              type="hidden"
                              name="amount"
                              value={order.paymentGateway.amount}
                            />
                            <input
                              type="hidden"
                              name="currency"
                              value={order.paymentGateway.currency}
                            />

                            <input
                              type="hidden"
                              name="oid"
                              value={order.paymentGateway.oid}
                            />
                            <input
                              type="hidden"
                              name="okUrl"
                              value={order.paymentGateway.okurl}
                            />
                            <input
                              type="hidden"
                              name="failUrl"
                              value={order.paymentGateway.failurl}
                            />
                            <input
                              type="hidden"
                              name="rnd"
                              value={order.paymentGateway.rnd}
                            />
                            <input type="hidden" name="lang" value="sr" />
                            <input
                              type="hidden"
                              name="shopurl"
                              value={order.paymentGateway.shopurl}
                            />

                            <input
                              type="hidden"
                              name="encoding"
                              value="utf-8"
                            />

                            <input
                              className="button-payment"
                              type="submit"
                              value="Plati"
                            />
                          </form>
                        </center>
                      )}
                    </>
                  )}
                </Typography>
                <hr style={{ width: '100%', margin: '10px 0' }} />
                <Typography variant="h6" alignSelf="center">
                  Porudzbina:
                </Typography>
                <div className="list">
                  {order.items.map((orderItem) => {
                    let cartProduct = {
                      id: orderItem.product_name,
                      quantity: orderItem.quantity,
                      height: orderItem.height,
                      width: orderItem.width,
                      color: orderItem.color,
                      price: orderItem.price,
                      transparency: orderItem.transparency,
                      colorAround: orderItem.colorAround,
                    };

                    // 'side' samo ako product_name !== "plise-komarnici"
                    if (orderItem.product_name === 'zebra-zavese') {
                      cartProduct.side = orderItem.side;
                    }

                    return <CartItem cartProduct={cartProduct} />;
                  })}
                </div>
                <hr style={{ width: '100%', margin: '10px 0' }} />

                <Typography variant="h6">Podaci o dostavi</Typography>
                <Stack sx={{ textAlign: 'left' }}>
                  <p>
                    Ime i Prezime: {order.first_name} {order.last_name}
                  </p>
                  <p>Telefon: {order.phone_number}</p>
                  <p>Email adresa: {order.email}</p>
                  <p>Ulica: {order.street}</p>
                  <p>Kućni broj: {order.house_number}</p>
                  <p>Poštanski broj: {order.postal_code}</p>
                  <p>Grad: {order.city}</p>
                  <p>
                    Dodatne informacije:
                    {order.additional_info}
                  </p>
                </Stack>

                {order.payment && (
                  <>
                    <hr style={{ width: '100%', margin: '10px 0' }} />

                    <Stack mt={6} sx={{ textAlign: 'left' }}>
                      <p>OrderID: {order.payment.oid}</p>
                      <p>AuthCode: {order.payment.auth_code}</p>
                      <p>Response: {order.payment.response}</p>
                      <p>ProcReturnCode: {order.payment.proc_return_code}</p>
                      <p>mdStatus: {order.payment.md_status}</p>
                      <p>EXTRA.TRXDATE: {order.payment.extra_trx_date}</p>
                    </Stack>
                  </>
                )}
              </Stack>
            </div>
          </StepTwoWrapper>
        </ShopWrapper>
      </ShopContainer>
    </div>
  );
};
