import { Box } from '@mui/material';
import React from 'react';
import { Circle, ChannelType } from '../../containers/ShopNew/Shop.styled';
import { colorOptions } from '../../helpers/products';

export const ColorPickerNew = ({
  colorsSet,
  selectedColor,
  handleColorSet,
  label,
}) => {
  return (
    <div className="colors">
      <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
        {label}:{' '}
        {selectedColor && (
          <span
            style={{
              fontSize: '16px',
              fontStyle: 'italic',
              fontWeight: '300',
              margin: 0,
            }}
          >
            {selectedColor}
          </span>
        )}
      </p>

      {label !== 'Broj kanala' && (
        <div>
          <Box
            display="flex"
            gap={colorsSet.length > 8 ? 1 : colorsSet.length > 5 ? 2 : 3}
            mt={2}
          >
            {colorsSet.map((color) => {
              // console.log('Processing color:', color); // Dodajte ovaj log pretrage boje
              const colorOption = colorOptions.find((c) => c.name === color);
              // console.log('Color option found:', colorOption); // Dodajte ovaj log da vidite šta se našlo
              if (!colorOption) return <></>;

              return (
                <Circle
                  key={colorOption.name}
                  value={colorOption.value}
                  backgroundColor={colorOption.value}
                  selectedColor={colorOption.value}
                  isActive={selectedColor === colorOption.name}
                  onClick={() => handleColorSet(colorOption.name)}
                />
              );
            })}
          </Box>
        </div>
      )}

      {label === 'Broj kanala' && (
        <div>
          <Box display="flex" gap={3} mt={2}>
            {colorsSet.map((color) => {
              if (!color) return null;

              return (
                <ChannelType
                  onClick={() => handleColorSet(color)}
                  isActive={selectedColor === color}
                >
                  {color}
                </ChannelType>
              );
            })}
          </Box>
        </div>
      )}
    </div>
  );
};
