import React from 'react';
import './section.css';
import { clsx } from 'clsx';

const Section = ({
  title,
  message,
  bgMedia,
  bgMediaMobile,
  content,
  className,
}) => {
  const desktop = window.innerWidth > 768;

  return (
    <section className={clsx('section_template', className)}>
      <div className="bg-section">{bgMedia}</div>
      {bgMediaMobile && (
        <div className="bg-section-mobile">{bgMediaMobile}</div>
      )}

      {content ? (
        <div className="section_content">{content}</div>
      ) : (
        <div className="section_content">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
      )}
    </section>
  );
};

export default Section;
