export const colorOptions = [
  // opste boje
  { name: 'Siva', value: '#747474' },
  { name: 'Svetlo siva', value: '#96a3a6' },
  { name: 'Svetlo siva', value: '#D9D9D9' },
  { name: 'Antracit', value: '#393939' },
  { name: 'Krem', value: '#dec0a8' },
  { name: 'Bela', value: '#EBEBEB' },
  { name: 'Crna', value: '#000000' },
  { name: 'Braon', value: '#6d625b' },
  { name: 'Zlatna', value: '#e8b923' },
  { name: 'Srebrna', value: '#C0C0C0' },
  // zebra A kategorija
  { name: 'AbuDhabi', value: '#717374' },
  { name: 'Algeria', value: '#AFA89D' },
  { name: 'Aman', value: '#868688' },
  { name: 'Amsterdam', value: '#E9E5E1' },
  { name: 'Ankara', value: '#E1E1E1' },
  { name: 'Atina', value: '#E8D4D4' },
  { name: 'Atlanta', value: '#D7CAB8' },
  // zebra B kategorija
  { name: 'Bangkok', value: '#E7E7E7' },
  { name: 'Barcelona', value: '#463F3F' },
  { name: 'Beirut', value: '#D5CBB6' },
  { name: 'Berlin', value: '#B7B0A5' },
  { name: 'Bern', value: '#D4D4D2' },
  { name: 'Brisel', value: '#A88C71' },
  { name: 'Buenosaires', value: '#655E61' },
  // zebra C kategorija
  { name: 'Cairo', value: '#888888' },
  { name: 'Canberra', value: '#E0DDD5' },
  { name: 'Capetown', value: '#9D8D80' },
  { name: 'Caracas', value: '#DEDAD5' },
  { name: 'Cardiff', value: '#D4C9BA' },
  { name: 'Casablanca', value: '#C1C1C1' },
  { name: 'Cayenne', value: '#858583' },
  { name: 'Copenhagen', value: '#BAA993' },

  // PLISE NOVE BOJE
  { name: 'Svetlo siva - 1401', value: '#a4a4ab' },
  { name: 'Bež - 1402', value: '#b0b3b0' },
  { name: 'Braon - 1403', value: '#928985' },
  { name: 'Tamno siva - 1404', value: '#79808b' },

  { name: 'Bela - 3001', value: '#d7d7d8' },
  { name: 'Bež - 3003', value: '#b3ada2' },
  { name: 'Svetlo braon - 3004', value: '#928985' },
  { name: 'Tamnije bež - 3005', value: '#bcb1a3' },
  { name: 'Braon - 3006', value: '#6d625b' },
  { name: 'Svetlo siva - 3009', value: '#a4a4a6' },
  { name: 'Tamno siva - 3010', value: '#79808b' },
  { name: 'Siva - 3011', value: '#b2b5b9' },
  { name: 'Plava - 3012', value: '#405c79' },

  { name: 'Bela - 5001', value: '#EBEBEB' },
  { name: 'Krem - 5002', value: '#acaca4' },
  { name: 'Svetlo braon - 5004', value: '#7a6f6a' },
  { name: 'Braon - 5006', value: '#6d625b' },
  { name: 'Svetlo siva - 5008', value: '#a4a4a6' },
  { name: 'Antracit - 5011', value: '#393939' },
];

export const products = [
  {
    id: 'plise-komarnici',
    img: '/images/shop/komarnik/komarnik-Bela.webp',
    imageColor: '/images/shop/komarnik/komarnik-{color}.webp',
    description: `Komarnici sprečavaju ulazak insekata u Vaš dom.
        Omogoćavaju nesmetano provetravanje prostorije.
        Zadržavaju prašinu i alergene spolja.
        Rok isporuke: 7-10 radnih dana.
        Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Komarnici',
    subtitle: ' ',
    maxWidth: 300,
    maxHeight: 300,
    colors: ['Bela', 'Braon', 'Antracit', 'Svetlo Siva'],
  },
  {
    id: 'zebra-zavese',
    img: '/images/shop/zebra/AbuDhabi.webp',
    imageColor: '/images/shop/zebra/{color}.webp',
    description: `Zebra zavese su vrsta rolo zavese sa kombinovanim (zebra) platnom. Pomeraju se gore-dole, namotavanjem na osovinu. 
        Prilikom pomeranja zavese dolazi do različite svetlopropusnosti. 
        Na ovaj način jednostavno kontrolišete količinu svetla u prostoriji i štitite se od neželjenih pogleda.
        Rok isporuke: 5-7 dana.
        Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: `Mini Zebra zavese`,
    subtitle: '(bez bušenja)',
    maxWidth: 150,
    maxHeight: 250,
    category1: [
      'Amsterdam',
      'Atina',
      'Ankara',
      'Atlanta',
      'Algeria',
      'Aman',
      'AbuDhabi',
    ],
    category2: [
      'Bangkok',
      'Bern',
      'Beirut',
      'Berlin',
      'Brisel',
      'Buenosaires',
      'Barcelona',
    ],
    category3: [
      'Canberra',
      'Caracas',
      'Cardiff',
      'Capetown',
      'Copenhagen',
      'Casablanca',
      'Cairo',
      'Cayenne',
    ],
  },
  {
    id: 'plise-zavese',
    img: '/images/shop/pliseZavese/bela/Svetlo siva - 1401.webp',
    imageColor: '/images/shop/pliseZavese/{colorAround}/{color}.webp',
    description: `Plise zavese su vrsta zavese koja se pomeraju gore-dole. 
    Pružaju elegantan i moderan izgled. Sprečavaju zadržavanje prašine i alergena u Vašem domu. 
    Blokiraju sunčevu svetlost i utiču na temperaturu. Rok dostave je 7-10 radnih dana.
    Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Plise zavese',
    subtitle: ' ',
    maxWidth: 150,
    maxHeight: 300,
    category1: [
      'Bela - 3001',
      'Bež - 3003',
      'Braon - 3006',
      'Plava - 3012',
      'Siva - 3011',
      'Svetlo siva - 3009',
      'Svetlo braon - 3004',
      'Tamnije bež - 3005',
      'Tamno siva - 3010',
    ],
    category2: [
      'Svetlo siva - 1401',
      'Bež - 1402',
      'Braon - 1403',
      'Tamno siva - 1404',
    ],
    category3: [
      'Bela - 5001',
      'Antracit - 5011',
      'Braon - 5006',
      'Krem - 5002',
      'Svetlo braon - 5004',
      'Svetlo siva - 5008',
    ],
    colorsAround: ['Bela', 'Antracit', 'Braon'],
  },
  {
    id: 'zebra-sa-maskom',
    img: '/images/shop/zebraSaMaskom/bela/AbuDhabi.webp',
    imageColor: '/images/shop/zebraSaMaskom/{colorAround}/{color}.webp',
    description: `Zebra zavese sa maskom predstavljaju funkcionalno i estetski privlačno rešenje za dekoraciju Vašeg prostora. 
        Omogućavaju jednostavno podešavanje svetlo-propusnosti, istovremeno utičući na privatnost. 
        Pomeraju se gore-gole namotavanjem na osovinu.
        Rok isporuke: 5-7 radnih dana.
        Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Zebra zavese',
    subtitle: 'Sa maskom',
    maxWidth: 300,
    maxHeight: 300,
    colorsAround: ['Bela', 'Crna'],
    category1: [
      'Amsterdam',
      'Atina',
      'Ankara',
      'Atlanta',
      'Algeria',
      'Aman',
      'AbuDhabi',
    ],
    category2: [
      'Bangkok',
      'Bern',
      'Beirut',
      'Berlin',
      'Brisel',
      'Buenosaires',
      'Barcelona',
    ],
    category3: [
      'Canberra',
      'Caracas',
      'Cardiff',
      'Capetown',
      'Copenhagen',
      'Casablanca',
      'Cairo',
      'Cayenne',
    ],
  },
  {
    id: 'zebra-bez-maske',
    img: '/images/shop/zebraBezMaske/bela/AbuDhabi.webp',
    imageColor: '/images/shop/zebraBezMaske/{colorAround}/{color}.webp',
    description: `Zebra zavese bez maske predstavljaju funkcionalno i estetski privlačno rešenje za dekoraciju Vašeg prostora. 
        Omogućavaju jednostavno podešavanje svetlo-propusnosti, istovremeno utičući na privatnost. 
        Pomeraju se gore-gole namotavanjem na osovinu.
        Rok isporuke: 5-7 radnih dana.
        Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Zebra zavese',
    subtitle: 'Bez maske',
    maxWidth: 300,
    maxHeight: 300,
    colorsAround: ['Bela', 'Crna'],
    category1: [
      'Amsterdam',
      'Atina',
      'Ankara',
      'Atlanta',
      'Algeria',
      'Aman',
      'AbuDhabi',
    ],
    category2: [
      'Bangkok',
      'Bern',
      'Beirut',
      'Berlin',
      'Brisel',
      'Buenosaires',
      'Barcelona',
    ],
    category3: [
      'Canberra',
      'Caracas',
      'Cardiff',
      'Capetown',
      'Copenhagen',
      'Casablanca',
      'Cairo',
      'Cayenne',
    ],
  },
  {
    id: 'paketo-zavese',
    img: '/images/shop/paketo/paketo-bela.webp',
    imageColor: '/images/shop/paketo/paketo-{color}.webp',
    description: `Rimske (paketo) zavese predstavljaju elegantno i praktično rešenje za dekoraciju Vašeg prostora. 
    Materijal koji se koristi za izradu ovih zavesa je imitacija lana koji Vam omogućava da jednostavno prilagodite svetlo i privatnost u svojim prostorijama.
    Rok isporuke je 10-14 radnih dana.
    Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Paketo (rimske) zavese',
    subtitle: ' ',
    maxWidth: 150,
    maxHeight: 260,
    colors: ['Bela', 'Krem', 'Siva'],
  },
  {
    id: 'kukice-okrugle',
    img: '/images/shop/kukice/KukiceOkruglaZlatna.webp',
    imageColor: '/images/shop/kukice/KukiceOkrugla{color}.webp',
    description: `Kukice za zavese olakšavaju postavljanje i dekoraciju.
    Dolaze u paru u zlatnoj ili srebrnoj boji.
    Rok isporuke je 7-10 radnih dana.
    Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Kukice',
    subtitle: 'Okrugle',
    maxWidth: 300,
    maxHeight: 300,
    colors: ['Zlatna', 'Srebrna'],
  },
  {
    id: 'kukice-dijamantske',
    img: '/images/shop/kukice/KukiceDijamantZlatna.webp',
    imageColor: '/images/shop/kukice/KukiceDijamant{color}.webp',
    description: `Kukice za zavese olakšavaju postavljanje i dekoraciju. 
    Dolaze u paru u zlatnoj ili srebrnoj boji.
    Rok isporuke je 7-10 radnih dana.
    Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Kukice',
    subtitle: 'Dijamantske',
    maxWidth: 300,
    maxHeight: 300,
    colors: ['Zlatna', 'Srebrna'],
  },
  {
    id: 'garnisne',
    img: '/images/shop/garnisne/garnisne-Dvokanalna.webp',
    imageColor: '/images/shop/garnisne/garnisne-{color}.webp',
    description: `Plafonske ALU garnišne su poseban tip držača za zavese koji se montira na plafon. 
    Ovi držači omogućavaju elegantno kačenje zavesa bez potrebe za bušenjem zidova. 
    Lako se postavljaju i omogućavaju Vam da prilagodite izgled prozora.
    Rok isporuke je 7-10 radnih dana.
    Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.`,
    title: 'Plafonske ALU garnišne',
    subtitle: ' ',
    maxWidth: 500,
    maxHeight: 100,
    colors: ['Dvokanalna', 'Trokanalna'],
  },
];

export function addToCart(product) {
  const cartRaw = window.localStorage.getItem('cart') ?? '[]';
  const cart = JSON.parse(cartRaw);
  product.rnd = Date.now();

  cart.push({
    id: product.id,
    rnd: product.rnd,
    height: product.height,
    width: product.width,
    color: product.color,
    colorAround: product.colorAround,
    price: product.price,
    side: product.side,
    transparency: product.transparency,
  });

  window.localStorage.setItem('cart', JSON.stringify(cart));
}

export function getCart() {
  const cartRaw = window.localStorage.getItem('cart') ?? '[]';
  const cart = JSON.parse(cartRaw);
  return cart;
}

export function saveCart(cart) {
  window.localStorage.setItem('cart', JSON.stringify(cart));
}
