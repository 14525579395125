import React from 'react';
import './BlogItem.css';

export default ({ title, date, content, imageUrl, onClick }) => {
  return (
    <div className={'blogItem'} onClick={onClick}>
      <img src={imageUrl} alt="blog" />
      <div>
        <small className={'blogItemDate'}>{date}</small>
        <h2 className={'blogItemTitle'}>{title}</h2>
        <div className={'blogItemContent'}>{content}</div>
        <div></div>

        <small className="blogItemReadMore">Pročitaj više</small>
      </div>
    </div>
  );
};
