import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/zavese/5.webp';
import SlideSecond from '../../assets/zavese/2.webp';
import SlideThird from '../../assets/zavese/3.webp';
import SlideFourth from '../../assets/zavese/4.webp';
import SlideFifth from '../../assets/zavese/1.webp';
import SlideSixth from '../../assets/zavese/9.webp';
// import Slideseventh from '../../assets/zavese/7.webp';

import SlideFirstMobile from '../../assets/zavese_mobile/curtain1.webp';
import SlideSecondMobile from '../../assets/zavese_mobile/curtain2.webp';
import SlideThirdMobile from '../../assets/zavese_mobile/curtain3.webp';
import SlideFourthMobile from '../../assets/zavese_mobile/curtain4.webp';
import SlideFifthMobile from '../../assets/zavese_mobile/curtain5.webp';
import SlideSixthMobile from '../../assets/zavese_mobile/curtain6.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth < 600;

const SLIDERS = [
  {
    title: 'Zavese i Draperije',
    message: 'Doprinose estetici prostora i unose toplinu.',
    image: desktop ? SlideFirstMobile : SlideFirst,
  },
  {
    title: 'Zavese i Draperije',
    message: 'Blokiraju neželjene poglede spolja.',
    image: desktop ? SlideSecondMobile : SlideSecond,
  },
  {
    title: 'Zavese i Draperije',
    message: 'Regulišu količinu svetlosti koja ulazi u prostoriju.',
    image: desktop ? SlideThirdMobile : SlideThird,
  },
  {
    title: 'Zavese i Draperije',
    message: 'Utiču na smanjenje buke koja dolazi spolja.',
    image: desktop ? SlideFourthMobile : SlideFourth,
  },
  {
    title: 'Zavese i Draperije',
    message: 'Izrađuju se po vašim merama i dimenzijama',
    image: desktop ? SlideFifthMobile : SlideFifth,
  },
  {
    title: 'Zavese i Draperije',
    message: 'Doprinose čistoći i kvalitetu vazduha',
    image: desktop ? SlideSixthMobile : SlideSixth,
  },
  // {
  //   title: 'Zavese i Draperije',
  //   message: 'Izrađuju se po vašim merama i dimenzijama',
  //   image: Slideseventh,
  // }
];

const ZaveseiDraperije = () => {
  return (
    <>
      <Helmet>
        <title>Zavese i draperije po meri - DaLux</title>
        <meta name="title" content="Zavese i draperije po meri - DaLux." />
        <meta
          name="description"
          content="Zavese i draperije najvišeg kvaliteta izrađeni baš po Vašoj meri. Kompletnu ponudu pogledajte na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/zavese-i-draperije" />
        <meta
          property="og:title"
          content="Zavese i draperije po meri - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/zavese-i-draperije"
        />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Zavese i draperije najvišeg kvaliteta izrađeni baš po Vašoj meri. Kompletnu ponudu pogledajte na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.0e40af3b21b0e3058443.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Zavese i draperije po meri - DaLux",
        "url": "https://www.dalux.rs/zavese-i-draperije",
        "description": "Zavese i draperije najvišeg kvaliteta izrađeni baš po Vašoj meri. Kompletnu ponudu pogledajte na sajtu Dalux.rs.",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Početna",
              "item": "https://www.dalux.rs"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Zavese i draperije",
              "item": "https://www.dalux.rs/zavese-i-draperije"
            }
          ]
        }
      }
    `}
        </script>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Zavese i draperije",
        "image": "https://www.dalux.rs/static/media/1.0e40af3b21b0e3058443.png",
        "description": "Zavese i draperije najvišeg kvaliteta izrađeni baš po Vašoj meri. Kompletnu ponudu pogledajte na sajtu Dalux.rs.",
        "brand": {
          "@type": "Brand",
          "name": "DaLux"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "RSD",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "name": "DaLux"
          },
          "url": "https://www.dalux.rs/zavese-i-draperije",
          "description": "Kontaktirajte nas za detalje o ceni."
        }
      }
    `}
        </script>
      </Helmet>
      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Kako se kreću cene zavesa i draperija?',
            message:
              'Cene zavesa i draperija se kreću u rasponu od 1.500rsd-18.000rsd po dužnom metru u zavisnosti od kvaliteta i porekla samih materijala. U ponudi imamo preko 6.000 modela.',
          },
          {
            title:
              'Da li postoji mogućnost izlaska na teren i prezentovanja materijala na licu mesta?',
            message:
              'Naravno! To je sastavni deo našeg rada. Ukoliko niste sigurni kako precizno da uzmete dimenzije nudimo Vam uslugu izlaska na teren koja se naplaćuje 3.000rsd na teritoriji Beograda. Pre svega je potrebno da posetite naš izložbeni salon radi odabira materijala.',
          },
          {
            title: 'Da li radite montažu zavesa?',
            message:
              'Ukoliko nemate uslove sami da montirate, nudimo Vam i uslugu montaže na teritoriji Beograda za koju je rok isporuke 2-3 nedelje od momenta kada se odaberu materijali. Naša ekipa montažera instalira isključivo proizvode koji su kupljeni kod nas.',
          },
          {
            title: 'Da li možemo sami da uzmemo dimenzije zavesa?',
            message:
              'Uvek možete sami da donesete Vaše dimenzije, takođe ukoliko niste sigurni kako to da uradite a ne želite da plaćate dodatno izlazak na teren, možete uvek da pozovete kontakt telefon našeg izložbenog salona i mi ćemo Vam besplatno objasniti kako da ne napravite grešku.',
          },
          {
            title:
              'Kakva je razlika u trakama koje se našivaju na gornji deo zavese?',
            message:
              'Traka diktira kako će zavesa izgledati. Stoga postoji nekoliko vrsta traka u našoj ponudi koje možete pogledati u našem izložbenom salonu na Banovom brdu.',
          },
          {
            title: 'Kako se održavaju zavese i draperije?',
            message:
              'Zavese i draperije se peru u veš mašini na 30 stepeni i sa minimalnom centrifugom. Nakon što je mašina završila, odmah okačite Vaše zavese iako su i dalje mokre nazad na garnišnu a onda će fabričko olovo koje je na dnu ispraviti zavesu tako da ne morate da ih peglate.',
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za sve naše proizvode je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstMobile}
      />
    </>
  );
};

export default ZaveseiDraperije;
