import React from 'react';
import './programSection.css';
import clsx from 'clsx';

const ProgramSection = ({ children, layout, className }) => {
  return (
    <div
      className={clsx(
        'programSection',
        {
          programSectionRightImage: layout === 'rightImage',
          programSectionItems: layout === 'items',
          programSectionFullWidthImage: layout === 'fullWidthImage',
          programSectionFullWidthImageRight: layout === 'fullWidthImageRight',
          programSectionLeftImage: layout === 'leftImage',
          programSectionLeftImage: layout === 'leftImageReverse',
          programSectionLeftImageReverse: layout === 'leftImageReverse',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default ProgramSection;
