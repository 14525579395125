import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  useEffect(() => {
    // Kreiranje Google Tag Manager skripte
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-CWJWF11XTV';
    document.body.appendChild(scriptTag);

    // Inicijalizacija Google Tag Managera
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-CWJWF11XTV');
  }, []);

  return (
    <Helmet>
      <title>DaLux | Online prodavnica</title>
      <script id="facebook-pixel-script">
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '682374767431509');
        fbq('track', 'PageView');`}
      </script>
      <noscript id="facebook-pixel-image">
        {`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=682374767431509&ev=PageView&noscript=1"
        />`}
      </noscript>
    </Helmet>
  );
};

export default MetaPixel;
