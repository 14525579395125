import React, { useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

import {
  StyledBoxOne,
  OverlayStyle,
  PopupStyle,
} from '../../containers/ShopNew/Shop.styled';
import { ColorPickerNew } from '../../components/ColorPicker/ColorPickerNew';
import { CartContext } from '../../CartContext';
import { toast } from 'react-toastify';
import Timer from '../../containers/Timer/Timer';

export const Product = ({ product }) => {
  const [transparency, setTransparency] = useState('');
  const [color, setColor] = useState(null);
  const [colorAround, setColorAround] = useState('Bela');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [side, setSide] = useState('desno');
  const [quantity, setQuantity] = useState(1);

  const [validInput, setValidInput] = useState(false);
  const [price, setPrice] = useState(0);

  const size = useWindowSize();

  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const { addToCart } = useContext(CartContext);
  const isMobile = size.width <= 768;

  const [selectedColor, setSelectedColor] = useState({
    category1: '',
    category2: '',
    category3: '',
  });

  const handleColorSelect = (category, color) => {
    setSelectedColor((prevState) => ({
      ...{ category1: '', category2: '', category3: '' },
      [category]: color,
    }));
  };

  const handleColorSelectPlise = (category, color) => {
    switch (category) {
      case 'category1':
        setTransparency('50%');
        break;
      case 'category2':
        setTransparency('10%');
        break;
      case 'category3':
        setTransparency('0%');
        break;
      default:
        console.log(
          'Erorr while settings transparency for category: ',
          category
        );
    }
    setSelectedColor((prevState) => ({
      ...{ category1: '', category2: '', category3: '' },
      [category]: color,
    }));
  };

  const productsWithColorAround = [
    'plise-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
  ];
  const productsWithOrientation = [
    'zebra-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'paketo-zavese',
  ];
  const productWithoutColors = [
    'garnisne',
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'zebra-zavese',
    'plise-zavese',
    'plise-komarnici',
  ];
  const productsWithoutDimensions = ['kukice-okrugle', 'kukice-dijamantske'];
  const productsZebra = ['zebra-sa-maskom', 'zebra-bez-maske', 'zebra-zavese'];
  const productsWithVideoDimensions = [
    'zebra-zavese',
    'paketo-zavese',
    'plise-komarnici',
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'plise-zavese',
  ];
  const colorAroundProducts = [
    'plise-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
  ];
  const alertMessage = `Proizvod je dodat u korpu! \nIzaberite "Dalje" za plaćanje ili "Nazad" za nastavak kupovine.\nUkoliko želite isti proizvod sa drugim specifikacijama, slobodno ga unesite.`;

  const resetFields = () => {
    setTransparency('');
    setColor(null);
    setColorAround('Bela');
    setSelectedColor({
      category1: '',
      category2: '',
      category3: '',
    });
    setHeight('');
    setWidth('');
    setSide('desno');
    setQuantity(1);
    setValidInput(false);
    setPrice(0);
  };

  const isInputValid = (product) => {
    switch (product.id) {
      case 'plise-zavese':
        return !!color && !!colorAround && !!width && !!height;
      case 'zebra-sa-maskom':
      case 'zebra-bez-maske':
        return !!color && !!colorAround && !!width && !!height;
      case 'zebra-zavese':
      case 'paketo-zavese':
      case 'plise-komarnici':
        return !!color && !!width && !!height;
      case 'garnisne':
        return !!color && !!width;
      case 'kukice-okrugle':
      case 'kukice-dijamantske':
        return !!color;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (isInputValid(product)) {
      setValidInput(true);

      let pricePerM2 = 0;
      let areaM2 = 1;
      let finalWidth = product.id === 'zebra-zavese' ? width + 3 : width;

      if (
        product.id !== 'garnisne' &&
        product.id !== 'paketo-zavese' &&
        !productsWithoutDimensions.includes(product.id)
      ) {
        areaM2 = (height * finalWidth) / 10000;
      } else if (product.id == 'garnisne') {
        areaM2 = finalWidth / 100;
      }

      if (areaM2 < 1) {
        areaM2 = 1;
      }

      if (product.id === 'zebra-zavese') {
        console.log(product.colors);
        if (color.toLowerCase().startsWith('a')) {
          pricePerM2 = 3600;
        } else if (color.toLowerCase().startsWith('b')) {
          pricePerM2 = 4000;
        } else if (color.toLowerCase().startsWith('c')) {
          pricePerM2 = 5000;
        }
      } else if (product.id === 'zebra-bez-maske') {
        if (color.toLowerCase().startsWith('a')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 5000;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 5500;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        } else if (color.toLowerCase().startsWith('b')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 5500;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 6100;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        } else if (color.toLowerCase().startsWith('c')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 6500;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 6900;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        }
      } else if (product.id === 'zebra-sa-maskom') {
        if (color.toLowerCase().startsWith('a')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 5500;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 6100;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        } else if (color.toLowerCase().startsWith('b')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 6000;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 6600;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        } else if (color.toLowerCase().startsWith('c')) {
          if (colorAround.toLowerCase().startsWith('b')) {
            pricePerM2 = 7000;
          } else if (colorAround.toLowerCase().startsWith('c')) {
            pricePerM2 = 7700;
          } else {
            pricePerM2 = 0;
            console.log('Greska pri racunanju cene za zebra zavese.');
          }
        }
      } else if (product.id === 'plise-zavese') {
        if (transparency === '50%') {
          pricePerM2 = 4000;
        } else if (transparency === '10%') {
          pricePerM2 = 5000;
        } else if (transparency === '0%') {
          pricePerM2 = 6000;
        }
      } else if (product.id === 'plise-komarnici') {
        pricePerM2 = color === 'Bela' ? 3500 : 4000;
      } else if (product.id === 'paketo-zavese') {
        const widths = [100, 110, 120, 130, 140, 150];
        const prices = [8960, 9610, 10260, 10910, 11560, 12210];
        let selectedWidth = widths.findIndex((w) => finalWidth <= w);
        if (selectedWidth === -1) {
          selectedWidth = widths.length - 1;
        }
        pricePerM2 = prices[selectedWidth];
      } else if (product.id === 'kukice-dijamantske') {
        pricePerM2 = 2000;
      } else if (product.id === 'kukice-okrugle') {
        pricePerM2 = 1000;
      } else if (product.id === 'garnisne') {
        pricePerM2 = color == 'Trokanalna' ? 1500 : 1250;
      }

      let totalPrice = pricePerM2 * areaM2 * quantity;

      setPrice(totalPrice);
    } else {
      setValidInput(false);
      setPrice(0);
    }
  }, [height, width, color, quantity, product, colorAround, transparency]);

  useEffect(() => {
    const activeCategory = Object.entries(selectedColor).find(
      ([_, value]) => value !== ''
    );
    if (activeCategory) {
      const [_, colorValue] = activeCategory;
      setColor(colorValue);
    } else {
      setColor(null);
    }
  }, [selectedColor]);

  useEffect(() => {
    let newVideoUrl;
    if (product.id === 'plise-zavese') {
      newVideoUrl = isMobile
        ? 'https://youtube.com/embed/pgTBTSsQVG0?si=UXD0rCtsIf9NL8oZ'
        : 'https://www.youtube.com/embed/ca-n39GNnwg?si=rk5VVAeS8wOE_uoy';
    } else if (product.id === 'zebra-zavese') {
      newVideoUrl = isMobile
        ? 'https://youtube.com/embed/hL28EjqUHhU?si=XE-BUCL4X-0Eok37'
        : 'https://www.youtube.com/embed/n7nAoMPKFdA?si=vzb6caMEvoxw-4VC';
    } else if (product.id === 'paketo-zavese') {
      newVideoUrl = isMobile
        ? 'https://youtube.com/embed/bG6wCpVvI8o?si=RqvA1mziCCjOy63H'
        : 'https://www.youtube.com/embed/ezRprOoYORo?si=86HIoPlRHxjRqgmG';
    } else if (product.id === 'zebra-sa-maskom') {
      newVideoUrl = isMobile
        ? 'https://youtube.com/embed/bk73xEiYbAk?si=F8Qsspw3G9D1E8_Z'
        : 'https://www.youtube.com/embed/mT6M7dWoXgw?si=WXRSsw7jDGbGXczM';
    } else if (product.id === 'zebra-bez-maske') {
      newVideoUrl = isMobile
        ? 'https://youtube.com/embed/_6rUTtBTmaA?si=1ejTD1mdDoPFBUXy'
        : 'https://www.youtube.com/embed/CRSviChXAxM?si=-ZIZo8cOiWgJbImn';
    } else if (product.id === 'plise-komarnici') {
      newVideoUrl = isMobile
        ? 'https://www.youtube.com/embed/6wYkmApp8ZY'
        : 'https://www.youtube.com/embed/QDE8W4Zl2ic?si=Y8HBvcCci0YF7AC5';
    }
    setVideoUrl(newVideoUrl);
  }, [product.id, isMobile]);

  const openPopup = () => {
    setShowPopup(true);
  };

  const handleHeightChange = (h) => {
    if (h > product.maxHeight) {
      setHeight(product.maxHeight);
    } else if (h < 0) {
      setHeight(0);
    } else {
      setHeight(parseInt(h));
    }
  };

  const handleWidthChange = (w) => {
    if (w > product.maxWidth) {
      setWidth(product.maxWidth);
    } else if (w < 0) {
      setWidth(0);
    } else {
      setWidth(parseInt(w));
    }
  };

  const handleAddToCart = () => {
    if (validInput) {
      addToCart({
        ...product,
        height,
        width,
        price,
        color,
        colorAround,
        side,
        quantity,
        transparency,
      });
      toast.success('Proizvod je dodat u korpu', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getPriceText = (productId) => {
    if (productId === 'plise-zavese') {
      return 'Kako biste saznali cenu, potrebno je da odaberete boju platna, količinu propustljivosti, boju profila i da unesete dimenzije.';
    } else if (productsWithColorAround.includes(productId)) {
      return 'Kako biste saznali cenu, potrebno je da odaberete boju platna, profila i da unesete dimenzije.';
    } else if (productId === 'garnisne') {
      return 'Kako biste saznali cenu, potrebno je da odaberete tip garnisne i unesete širinu u cm.';
    } else if (productsWithoutDimensions.includes(product.id)) {
      return 'Kako biste saznali cenu, potrebno je da odaberete boju kukica.';
    } else if (productId == 'plise-komarnici') {
      return 'Kako biste saznali cenu, potrebno je da odaberete boju profila i da unesete dimenzije.';
    } else {
      // paketo, zebra obicna
      return 'Kako biste saznali cenu, potrebno je da odaberete boju platna i da unesete dimenzije.';
    }
  };

  if (!product) {
    return (
      <>
        <h1>Nepostojeći proizvod, molimo Vas da pokušate ponovo.</h1>
      </>
    );
  }

  return (
    <Stack>
      <OverlayStyle show={showPopup} onClick={() => setShowPopup(false)} />
      <PopupStyle show={showPopup}>
        {showPopup && (
          <iframe
            width="100%"
            height="100%"
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            title="Video player"
          ></iframe>
        )}
      </PopupStyle>

      <StyledBoxOne>
        <Stack
          spacing={productsWithColorAround.includes(product.id) ? 1 : 2}
          sx={{ maxWidth: '600px', textAlign: 'left' }}
        >
          <div className="description">
            <h6>Opis</h6>
            <p>{product.description}</p>
          </div>

          {product.id === 'plise-komarnici' && isMobile && (
            <Timer initialHours={48} />
          )}

          {size.width <= 1400 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {
                <img
                  src={
                    colorAroundProducts.includes(product.id) &&
                    color &&
                    colorAround
                      ? product.imageColor
                          .replace('{color}', color)
                          .replace('{colorAround}', colorAround.toLowerCase())
                      : color
                      ? product.imageColor.replace('{color}', color)
                      : product.img
                  }
                  className="chosen_image"
                  alt="chosen_image"
                  style={{ maxWidth: '350px' }}
                />
              }
            </Box>
          )}

          {product.id === 'garnisne' && (
            <ColorPickerNew
              colorsSet={product.colors}
              selectedColor={color}
              handleColorSet={(c) => {
                setColor(c);
              }}
              label="Broj kanala"
            />
          )}

          {product.id === 'plise-komarnici' && (
            <ColorPickerNew
              colorsSet={product.colors}
              selectedColor={color}
              handleColorSet={(c) => {
                setColor(c);
              }}
              label="Boja profila"
            />
          )}

          {product.id === 'plise-zavese' && (
            <>
              <ColorPickerNew
                colorsSet={product.category1}
                selectedColor={selectedColor.category1}
                handleColorSet={(color) => {
                  handleColorSelectPlise('category1', color);
                }}
                label="Propustljivost 50%, boja"
              />
              <ColorPickerNew
                colorsSet={product.category2}
                selectedColor={selectedColor.category2}
                handleColorSet={(color) => {
                  handleColorSelectPlise('category2', color);
                }}
                label="Propustljivost 10%, boja"
              />
              <ColorPickerNew
                colorsSet={product.category3}
                selectedColor={selectedColor.category3}
                handleColorSet={(color) => {
                  handleColorSelectPlise('category3', color);
                }}
                label="Propustljivost 0%, boja"
              />
            </>
          )}

          {!productWithoutColors.includes(product.id) && (
            <ColorPickerNew
              colorsSet={product.colors}
              selectedColor={color}
              handleColorSet={(c) => {
                setColor(c);
              }}
              label="Boja"
            />
          )}

          {productsZebra.includes(product.id) && (
            <>
              <ColorPickerNew
                colorsSet={product.category1}
                selectedColor={selectedColor.category1}
                handleColorSet={(color) =>
                  handleColorSelect('category1', color)
                }
                label="Kategorija A"
              />
              <ColorPickerNew
                colorsSet={product.category2}
                selectedColor={selectedColor.category2}
                handleColorSet={(color) =>
                  handleColorSelect('category2', color)
                }
                label="Kategorija B"
              />
              <ColorPickerNew
                colorsSet={product.category3}
                selectedColor={selectedColor.category3}
                handleColorSet={(color) =>
                  handleColorSelect('category3', color)
                }
                label="Kategorija C"
              />
            </>
          )}

          {productsWithColorAround.includes(product.id) && (
            <ColorPickerNew
              colorsSet={product.colorsAround}
              selectedColor={colorAround}
              handleColorSet={(c) => {
                setColorAround(c);
              }}
              label="Boja profila"
            />
          )}

          {!productsWithoutDimensions.includes(product.id) && (
            <div
              className={
                productsWithColorAround.includes(product.id)
                  ? 'dimensionsColorAround'
                  : 'dimensions'
              }
            >
              <p style={{ fontWeight: 'bold' }}>
                Dimenzije{' '}
                {productsWithVideoDimensions.includes(product.id) && (
                  <span onClick={openPopup} style={{ cursor: 'pointer' }}>
                    (Kako uzeti dimenzije?)
                  </span>
                )}
              </p>

              <div className="inputs">
                <Stack>
                  <label htmlFor="width">Širina (u cm)</label>
                  <input
                    type="number"
                    name="width"
                    min="0"
                    value={width}
                    onChange={(e) => handleWidthChange(e.target.value)}
                  />
                  <label>Maksimalno: {product.maxWidth}cm</label>
                </Stack>
                {product.id !== 'garnisne' && (
                  <Stack>
                    <label htmlFor="height">Visina (u cm)</label>
                    <input
                      type="number"
                      name="height"
                      min="0"
                      value={height}
                      onChange={(e) => handleHeightChange(e.target.value)}
                    />
                    <label>Maksimalno: {product.maxHeight}cm</label>
                  </Stack>
                )}
                {productsWithOrientation.includes(product.id) && (
                  <Stack>
                    <label htmlFor="chain">Orijentacija lanca</label>
                    <select
                      value={side}
                      name="chain"
                      onChange={(e) => setSide(e.target.value)}
                    >
                      <option value="desno" defaultChecked>
                        Desno
                      </option>
                      <option value="levo">Levo </option>
                    </select>
                  </Stack>
                )}
                <Stack>
                  <label htmlFor="quantity">Količina</label>
                  <input
                    type="number"
                    name="quantity"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Stack>
              </div>
            </div>
          )}

          {productsWithoutDimensions.includes(product.id) && (
            <div
              className={
                productsWithColorAround.includes(product.id)
                  ? 'dimensionsColorAround'
                  : 'dimensions'
              }
            >
              <div className="inputs">
                <Stack>
                  <label htmlFor="quantity">Količina</label>
                  <input
                    type="number"
                    name="quantity"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Stack>
              </div>
            </div>
          )}

          <div
            className={
              productsWithColorAround.includes(product.id)
                ? 'priceColorAround'
                : 'price'
            }
          >
            <p style={{ fontWeight: 'bold' }}>Cena:</p>
            <span style={{ fontSize: '16px' }}>
              {validInput ? (
                <>
                  <span style={{ color: '#ffc700' }}>{price.toFixed(2)}</span>{' '}
                  RSD
                </>
              ) : (
                <>
                  <p>{getPriceText(product.id)}</p>
                </>
              )}
            </span>
          </div>

          <button
            className="add_to_cart"
            disabled={!isInputValid(product)}
            onClick={() => {
              addToCart({
                ...product,
                height,
                width,
                price,
                color,
                colorAround,
                side,
                quantity,
                transparency,
              });

              toast.success('Proizvod je dodat u korpu', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
              resetFields();
            }}
          >
            <span className="icon"></span>
            Dodaj u korpu
          </button>
        </Stack>

        {size.width > 1400 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {
              <img
                src={
                  colorAroundProducts.includes(product.id) &&
                  color &&
                  colorAround
                    ? product.imageColor
                        .replace('{color}', color)
                        .replace('{colorAround}', colorAround.toLowerCase())
                    : color
                    ? product.imageColor.replace('{color}', color)
                    : product.img
                }
                className="chosen_image"
                alt="chosen_image"
                style={{ maxWidth: '550px', maxHeight: '605px' }}
              />
            }
          </Box>
        )}
      </StyledBoxOne>
    </Stack>
  );
};
