import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import Container from '../container/Container';
import insta from '../../assets/insta.svg';
import tiktok from '../../assets/tiktok.svg';
import youtube from '../../assets/youtube.png';
import logo from '../../assets/logo.png';
import GoogleMap from '../../assets/googleMapa.png';

import visaLogo from '../../assets/bankCards/visa.png';
import mastercardLogo from '../../assets/bankCards/mc.png';
import maestroLogo from '../../assets/bankCards/maestro.png';
import amexLogo from '../../assets/bankCards/amex.png';
import dinaLogo from '../../assets/bankCards/dina.png'; // bila jpg
import visaSecureLogo from '../../assets/bankCards/visaSecure.png'; // bila jpg
import mastercardSecureCodeLogo from '../../assets/bankCards/masterIdCheck.png';
import bankLogo from '../../assets/bankCards/intesa.png';
const Footer = () => {
  const desktop = window.innerWidth > 600;

  return (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/dashboard' && (
      <footer className="daLux_footer">
        <Container>
          <div className="footer_sections">
            <div className="footer_links">
              <h4>Proizvodi</h4>
              <ul>
                <li>
                  <Link to="/zavese-i-draperije">Zavese i Draperije</Link>
                </li>
                <li>
                  <Link to="/plise-zavese">Plise zavese</Link>
                </li>
                <li>
                  <Link to="/zebra-zavese">Zebra zavese</Link>
                </li>
                <li>
                  <Link to="/rolo-zavese">Rolo zavese</Link>
                </li>
                <li>
                  <Link to="/rimske-zavese">Rimske zavese</Link>
                </li>
                <li>
                  <Link to="/panel-zavese">Panelne zavese</Link>
                </li>
                <li>
                  <Link to="/drveni-venecijaneri">Drveni venecijaneri</Link>
                </li>
                <li>
                  <Link to="/zip-sistemi">Zip sistemi</Link>
                </li>
                <li>
                  <Link to="/tapete">Tapete</Link>
                </li>
                <li>
                  <Link to="/plise-zavese">Komarnici</Link>
                </li>
              </ul>
            </div>
            <div className="footer_links">
              <h4>Programi</h4>
              <ul>
                <li>
                  <Link to="/hotelski-program">Hotelski progam</Link>
                </li>
                <li>
                  <Link to="/korporativni-program">Korporativni program</Link>
                </li>
                <li>
                  <Link to="/program-za-arhitekte">Program za arhitekte</Link>
                </li>
                <li>
                  <Link to="/servis-zavesa">Servis zavesa</Link>
                </li>
              </ul>
              <h4 className="OstaloInfo">Ostalo</h4>
              <ul>
                <li>
                  <Link to="/shop">Shop online</Link>
                </li>
                <li>
                  <Link to="/zaposlenje">Zaposlenje</Link>
                </li>
                <li>
                  <Link to="/Blog">Blog</Link>
                </li>
                <li>
                  <Link to="/o-nama">O nama</Link>
                </li>
              </ul>
            </div>
            {!desktop && (
              <div className="bankCardsOuter">
                <div>
                  <a
                    href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={mastercardLogo}
                      alt="MasterCard"
                      className="cardLogo"
                    />
                  </a>
                  <a>
                    <img src={maestroLogo} alt="Maestro" className="cardLogo" />
                  </a>
                  <a>
                    <img src={dinaLogo} alt="Dina" className="cardLogo" />
                  </a>
                  <a
                    href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={visaLogo} alt="Visa" className="cardLogo" />
                  </a>
                  <a>
                    <img src={amexLogo} alt="Amex" className="cardLogo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.bancaintesa.rs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={bankLogo}
                      alt="Banca Intesa"
                      className="cardLogo bankLogo"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={mastercardSecureCodeLogo}
                      alt="MasterCard SecureCode"
                      className="cardProgram"
                      style={{ height: '60px' }}
                    />
                  </a>
                  <a
                    href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={visaSecureLogo}
                      alt="Visa Secure"
                      className="cardProgram"
                      style={{ height: '60px' }}
                    />
                  </a>
                </div>
              </div>
            )}
            <div className="footer_links footer_links_contact">
              <h4>Kontakt</h4>
              <div className="footer_contact">
                <div>
                  <ul>
                    <li>
                      <a href="mailto:info@dalux.rs">info@dalux.rs</a>
                    </li>
                    <li>
                      <a href="tel:0641185059">064/1185-059</a>
                    </li>
                    <li>
                      <a href="tel:0112394835">011/2394-835</a>
                    </li>
                  </ul>
                  <div className="footer_social_links">
                    <a href="https://www.instagram.com/zavese_dalux/">
                      <img src={insta} alt="instagram"></img>
                    </a>
                    <a href="https://www.tiktok.com/@zavese_dalux?lang=en">
                      <img src={tiktok} alt="tik_tok"></img>
                    </a>
                    <a
                      href="https://www.youtube.com/@zavesedalux"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={youtube} alt="youtube" />
                    </a>
                  </div>
                  <ul>
                    <li>
                      Aleksandar Andonovski preduzetnik <br></br>Gradjevinska
                      radnja Dalux
                    </li>
                    <li>Adresa: Požeška 148A, Banovo Brdo</li>
                    <li>Šifra delatnosti: 4332 i 1392</li>
                    <li>Matični broj: 54635770</li>
                    <li>Poreski broj: 100419232</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_contact">
              <p>Izložbeni salon: Požeška 148A, Banovo Brdo, Beograd</p>
              <a href="https://goo.gl/maps/bREkVN2M4PtR7LYS7">
                <img className="map" src={GoogleMap} alt="googleMapa"></img>
              </a>
              {desktop && (
                <div className="bankCards">
                  <a
                    href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={mastercardLogo}
                      alt="MasterCard"
                      className="cardLogo"
                    />
                  </a>
                  <a>
                    <img src={maestroLogo} alt="Maestro" className="cardLogo" />
                  </a>
                  <a>
                    <img src={dinaLogo} alt="Dina" className="cardLogo" />
                  </a>
                  <a
                    href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={visaLogo} alt="Visa" className="cardLogo" />
                  </a>
                  <a>
                    <img src={amexLogo} alt="Amex" className="cardLogo" />
                  </a>
                  <a
                    href="https://www.bancaintesa.rs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={bankLogo}
                      alt="Banca Intesa"
                      className="cardLogo bankLogo"
                    />
                  </a>
                  <a
                    href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={mastercardSecureCodeLogo}
                      alt="MasterCard SecureCode"
                      className="cardProgram"
                      style={{ height: '60px' }}
                    />
                  </a>
                  <a
                    href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={visaSecureLogo}
                      alt="Visa Secure"
                      className="cardProgram"
                      style={{ height: '60px' }}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </Container>
        <div className="footer_copyright">
          <img src={logo} alt="logo"></img>
          <p>
            Dalux © 2024 Sva prava zadržana. Izrada sajta{' '}
            <a href="https://www.mastermedia.net/">Master Media.</a>
          </p>
        </div>
      </footer>
    )
  );
};
export default Footer;
