import React from 'react';
import './programSectionItem.css';

const programSectionItem = ({ title, message, image, imageClassName }) => {
  return (
    <div className="programSectionItem">
      {image && (
        <div className="programSectionItemImg">
          <img src={image} className={imageClassName} alt="section-imag"></img>
        </div>
      )}
      {(title || message) && (
        <div className="programSectionItemText">
          {title && <h4>{title}</h4>}

          {typeof message === 'string' ? (
            <p>{message}</p>
          ) : (
            <div className="programSectionItemMessage">{message}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default programSectionItem;
