import { React, useEffect } from 'react';
import { termsAndConditions } from '../../helpers/terms';
import {
  ModalConatiner,
  ModalWrapper,
  ModalCloseBtn,
  ModalContent,
} from './Modal.styled';

export const Modal = ({ children, setOpen }) => {
  useEffect(() => {
    const modalElement = document.getElementById('modal-wrapper');

    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

      // Na vrhu
      if (scrollTop === 0 && e.deltaY < 0) {
        e.preventDefault();
      }
      // Na dnu
      else if (scrollTop + clientHeight === scrollHeight && e.deltaY > 0) {
        e.preventDefault();
      }
    };

    modalElement?.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      modalElement?.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <ModalConatiner onClick={() => setOpen(false)}>
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        id="modal-wrapper" // Dodajte ovaj ID
      >
        <ModalCloseBtn onClick={() => setOpen(false)}>X</ModalCloseBtn>
        <ModalContent>
          {termsAndConditions.map((term) => {
            return (
              <div>
                <h3>{term.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: term.text }}></p>
              </div>
            );
          })}
        </ModalContent>
      </ModalWrapper>
    </ModalConatiner>
  );
};

export default Modal;
