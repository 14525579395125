import styled from 'styled-components';

export const ModalConatiner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centriranje sadržaja */
  position: relative; /* Pozicioniranje u odnosu na ModalContainer */
  max-width: 90%; /* Maksimalna širina da se ne proteže na celu širinu ekrana */
  max-height: 80%; /* Maksimalna visina da se ne proteže na celu visinu ekrana */
  overflow-y: auto; /* Omogućavanje skrolovanja kada je sadržaj predugačak */
`;

export const ModalCloseBtn = styled.div`
  position: absolute; /* Pozicioniranje u odnosu na ModalWrapper */
  top: 10px; /* Razmak od vrha */
  right: 10px; /* Razmak od desne strane */
  cursor: pointer;
  color: white;
  background-color: #ff3128;
  font-family: cursive;
  padding: 10px; /* Prostor oko teksta */
  font-size: 16px;
  border-radius: 3px;
`;

export const ModalContent = styled.div`
  padding: 50px; /* Dodaje padding unutar modala */

  h3 {
    margin-bottom: 5px; /* Dodaje razmak ispod naslova */
    font-size: 22px;
  }

  p {
    margin-bottom: 30px; /* Dodaje razmak ispod paragrafa */
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
`;
