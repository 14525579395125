import React, { useContext, useState } from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';
import { Container } from '../';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import insta from '../../assets/insta.svg';
import tiktok from '../../assets/tiktok.svg';
import youtube from '../../assets/youtube.png';
import { CartContext } from '../../CartContext';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { CartItem } from '../ShopNew/CartItem';
import '../buttonLink/buttonLink.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const location = useLocation();
  const { cart, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const closeMenu = () => setToggleMenu(false);
  const logoElement = (
    <Link
      to="/"
      onClick={() => document.querySelector('.wrapper').scrollTo(0, 0)}
    >
      <img src={logo} alt="logo" />
    </Link>
  );

  const productPaths = [
    '/zavese-i-draperije',
    '/plise-zavese',
    '/zebra-zavese',
    '/komarnici',
    '/rolo-zavese',
    '/rimske-zavese',
    '/panel-zavese',
    '/drveni-venecijaneri',
    '/zip-sistemi',
    '/tapete',
  ];

  const productMap = {
    '/plise-zavese': 'plise-zavese',
    '/komarnici': 'plise-komarnici',
    '/rimske-zavese': 'paketo-zavese',
  };

  const getProductPageLink = () => {
    return productMap[location.pathname]
      ? `/shop?product=${productMap[location.pathname]}`
      : '/shop';
  };

  const showShopButton =
    productPaths.some((path) => location.pathname.includes(path)) &&
    !toggleMenu;

  return (
    <div
      className={clsx('daLux_navbar', {
        navbar_black:
          location.pathname.startsWith('/blog') ||
          location.pathname.startsWith('/shop') ||
          location.pathname.startsWith('/login') ||
          location.pathname.startsWith('/dashboard') ||
          location.pathname.startsWith('/zaposlenje') ||
          location.pathname.startsWith('/o-nama'),
      })}
    >
      <Container>
        {location.pathname === '/' ? (
          <h1 className="daLux_navbar_logo">{logoElement}</h1>
        ) : (
          <div className="daLux_navbar_logo">{logoElement}</div>
        )}

        <div className="daLux_navbar_container">
          <div>
            <a href="/zavese-i-draperije">Zavese i Draperije</a>
          </div>
          <div>
            <Link to="/plise-zavese">Plise</Link>
          </div>
          <div>
            <Link to="/zebra-zavese">Zebra</Link>
          </div>
          <div>
            <Link to="/komarnici">Komarnici</Link>
          </div>
          <div>
            <Link to="/rolo-zavese">Rolo</Link>
          </div>
          <div>
            <Link to="/rimske-zavese">Rimske</Link>
          </div>
          <div>
            <Link to="/panel-zavese">Panelne</Link>
          </div>
          <div>
            <Link to="/drveni-venecijaneri">Drveni venecijaneri</Link>
          </div>
          <div>
            <Link to="/zip-sistemi">Zip sistem</Link>
          </div>
          <div>
            <Link to="/tapete">Tapete </Link>
          </div>
          <div className="dropdown">
            <p className="dropdown-link">Programi</p>
            <div className="dropdown-menu">
              <Link to="/hotelski-program">Hotelski program</Link>
              <Link to="/korporativni-program">Korporativni program</Link>
              <Link to="/program-za-arhitekte">Program za arhitekte</Link>
              <Link to="/servis-zavesa">Servis zavesa</Link>
              <Link to="/zaposlenje">Zaposlenje</Link>
            </div>
          </div>
          <div>
            <Link to="/shop">
              <strong>SHOP</strong>
            </Link>
          </div>
        </div>
        <div className="daLux_navbar_links">
          <a href="tel:064/1185-059" type="button" className="contact_btn">
            Kontakt
          </a>
          <a
            href="https://www.instagram.com/zavese_dalux/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="insta" />
          </a>
          <a
            href="https://www.tiktok.com/@zavese_dalux?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tiktok} alt="tiktok" />
          </a>
          <a
            href="https://www.youtube.com/@zavesedalux"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
        </div>
        <div className="daLux_navbar-menu">
          {location.pathname.startsWith('/shop') && (
            <button className={clsx('menu-icon', 'menu-icon-cart')}>
              {!cartOpen && !toggleMenu && (
                <>
                  <img
                    onClick={() => {
                      setCartOpen(!cartOpen);
                      setToggleMenu(false);
                    }}
                    src={'/images/cart-white.svg'}
                    alt="cart"
                    loading="lazy"
                    className="cart"
                  />
                  {cart.length > 0 && (
                    <div className="cart-badge">{cart.length}</div>
                  )}
                </>
              )}
            </button>
          )}
          {showShopButton && (
            <button
              className="buttonLink shopButtonMobile"
              onClick={() => (window.location.href = getProductPageLink())}
            >
              SHOP
            </button>
          )}
          <button
            onClick={() => {
              setToggleMenu(cartOpen || toggleMenu ? false : true);
              setCartOpen(false);
            }}
            className={clsx('menu-icon', { open: toggleMenu || cartOpen })}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>

          <div
            className={clsx('daLux_navbar-menu_container', {
              active: toggleMenu,
            })}
          >
            <div className="daLux_navbar-menu_container-links">
              <div>
                <Link to="/zavese-i-draperije" onClick={closeMenu}>
                  Zavese i Draperije
                </Link>
              </div>
              <div>
                <Link to="/plise-zavese" onClick={closeMenu}>
                  Plise zavese
                </Link>
              </div>
              <div>
                <Link to="/zebra-zavese" onClick={closeMenu}>
                  Zebra zavese
                </Link>
              </div>
              <div>
                <Link to="/komarnici" onClick={closeMenu}>
                  Komarnici
                </Link>
              </div>
              <div>
                <Link to="/rolo-zavese" onClick={closeMenu}>
                  Rolo zavese
                </Link>
              </div>
              <div>
                <Link to="/rimske-zavese" onClick={closeMenu}>
                  Rimske zavese
                </Link>
              </div>
              <div>
                <Link to="/panel-zavese" onClick={closeMenu}>
                  Panelne zavese
                </Link>
              </div>
              <div>
                <Link to="/drveni-venecijaneri" onClick={closeMenu}>
                  Drveni venecijaneri
                </Link>
              </div>
              <div>
                <Link to="/zip-sistemi" onClick={closeMenu}>
                  Zip Sistemi
                </Link>
              </div>
              <div>
                <Link to="/tapete" onClick={closeMenu}>
                  {' '}
                  Tapete
                </Link>
              </div>
              <div>
                <Link to="/zaposlenje" onClick={closeMenu}>
                  Zaposlenje
                </Link>
              </div>
              <div>
                <Link to="/program-za-arhitekte" onClick={closeMenu}>
                  Program za arhitekte
                </Link>
              </div>
              <div>
                <Link to="/hotelski-program" onClick={closeMenu}>
                  Hotelski program
                </Link>
              </div>
              <div>
                <Link to="/korporativni-program" onClick={closeMenu}>
                  Korporativni program
                </Link>
              </div>
              <div>
                <Link to="/shop" onClick={closeMenu}>
                  Shop online
                </Link>
              </div>
            </div>
            <div className="footer_social_links">
              <a href="https://www.instagram.com/zavese_dalux/">
                <img src={insta} alt="instagram"></img>
              </a>
              <a href="https://www.tiktok.com/@zavese_dalux?lang=en">
                <img src={tiktok} alt="tik_tok"></img>
              </a>
              <a
                href="https://www.youtube.com/@zavesedalux"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="youtube" />
              </a>
            </div>
            <ul className="navbar_contact_info">
              <li>
                <a href="mailto:info@dalux.rs">info@dalux.rs</a>
              </li>
              <li>
                <a href="tel:0641185059">064/1185-059</a>
              </li>
              <li>
                <a href="tel:0112394835">011/2394-835</a>
              </li>
            </ul>
          </div>

          <div
            className={clsx('daLux_navbar-menu_container', {
              active: cartOpen,
            })}
          >
            <Stack gap={0}>
              {cart.length > 0 &&
                cart.map((cartProduct) => {
                  return (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      className="outerDiv"
                      gap={1.5}
                    >
                      <CartItem cartProduct={cartProduct} />
                      <img
                        src="/images/trash.png"
                        alt="trash"
                        height={28}
                        className="trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeFromCart(cartProduct)}
                      />
                    </Box>
                  );
                })}
            </Stack>
            {cart.length > 0 && (
              <>
                <p className="total_price">
                  Ukupna cena:{' '}
                  {cart.reduce((acc, p) => acc + p.price, 0).toFixed(2)} RSD
                </p>
                <button
                  className="proceed-to-checkout-btn"
                  onClick={() => {
                    navigate('/shop?step=2');
                    setCartOpen(false);
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    width: '100%',
                    padding: '10px',
                    marginTop: '10px',
                    backgroundColor: 'white',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  Nastavi ka plaćanju
                </button>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Navbar;
