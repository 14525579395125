import React, { useEffect, useContext, useState } from 'react';
import {
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@mui/material';
import { CartContext } from '../../CartContext';
import { Modal } from '../Modal/Modal';

export const Payment = () => {
  const { legal, setLegal, setPaymentType } = useContext(CartContext);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setPaymentType(null);
    setLegal(false);
  }, []);

  return (
    <Stack alignItems="center" width="100%">
      <Typography variant="h6" mt={4} alignSelf="center">
        Način plaćanja
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="payment-method"
          name="payment-method"
          onChange={(e) => setPaymentType(e.target.value)}
        >
          <FormControlLabel
            value="karticno"
            control={<Radio />}
            label="Plaćanje karticom"
          />
          <FormControlLabel
            value="pouzece"
            control={<Radio />}
            label="Plaćanje pouzećem"
          />
        </RadioGroup>
      </FormControl>

      <FormControl>
        {modalOpen && <Modal setOpen={setModalOpen} />}

        <FormControlLabel
          control={
            <Checkbox
              checked={legal}
              onChange={(e) => setLegal(e.target.checked)}
            />
          }
          label={
            <Typography variant="body2">
              Prihvatam{' '}
              <span
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => setModalOpen(true)}
              >
                opšte uslove kupovine u internet prodavnici
              </span>
            </Typography>
          }
        />
      </FormControl>
    </Stack>
  );
};
