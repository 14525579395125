import React from 'react';
import {
  ProgramSection,
  ProgramHeader,
  ProgramSectionItem,
  Container,
} from '../../components';
import servisHero from '../../assets/servis/servis-hero.png';
import pranje from '../../assets/servis/1.png';
import peglanje from '../../assets/servis/2.png';
import demontaza from '../../assets/servis/3.png';
import montaza from '../../assets/servis/4.png';
import './servisZavesa.css';
import servisHeroMobile from '../../assets/servis/servis-hero_mobile.png';
import { Helmet } from 'react-helmet';

const ServisZavesa = () => {
  return (
    <>
      <Helmet>
        <title>Servis i održavanje zavesa - DaLux</title>
        <meta name="title" content="Servis i održavanje zavesa - DaLux" />
        <meta
          name="description"
          content="Pranje, peglanje, demontaža i montaža zavesa. Posetite sajt Dalux.rs i saznajte više."
        />
        <link rel="canonical" href="https://www.dalux.rs/servis-zavesa" />

        <meta
          property="og:title"
          content="Servis i održavanje zavesa - DaLux"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/servis-zavesa" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Pranje, peglanje, demontaža i montaža zavesa. Posetite sajt Dalux.rs i saznajte više."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/servis-hero.8d05e0f0ef56a62b9f36.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1002" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Servis zavesa",
            "url": "https://www.dalux.rs/servis-zavesa",
            "description": "Profesionalni servis zavesa za čišćenje, popravku i održavanje vaših zavesa.",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Početna",
                  "item": "https://www.dalux.rs/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Servis zavesa",
                  "item": "https://www.dalux.rs/servis-zavesa"
                }
              ]
            }
          }
        `}
        </script>
      </Helmet>

      <div className="servicePage">
        <ProgramHeader
          title="Servis zavesa"
          message="Servisiranje i održavanje zavesa."
          image={servisHero}
          imageMobile={servisHeroMobile}
        />

        <Container>
          <ProgramSection layout="items">
            <ProgramSectionItem
              title="Pranje"
              message="Naša usluga pranja zavesa pruža Vam temeljno i efikasno čišćenje bez oštećenja tkanine. Stručno osoblje koristi posebna sredstva koja otklanjaju mrlje, prašinu i alergene kao i mašine koje brže i efikasnije uklanjaju prljavštinu. Čiste zavese unose svežinu u vaš prostor i poboljšavaju kvalitet vazduha."
              image={pranje}
            />
            <ProgramSectionItem
              title="Peglanje"
              message="Peglanje zavesa je važan korak odmah nakon pranja kako biste postigli besprekoran izgled i ravne linije. Upotreba specijalne pegle sa odgovarajućom temperaturom neće progoreti tkaninu, niti je sasušiti i zgužvati. Takođe, redovno peglanje i pranje zavesa utiče na dugotrajnost tkanine."
              image={peglanje}
            />
          </ProgramSection>

          <ProgramSection layout="items">
            <ProgramSectionItem
              title="Demontaža"
              message="Naš tim iskusnih montažera pažljivo će rukovati vašim zavesama kako se ne bi oštetile ali i kako bi obezbedili da sve bude spremno za ponovno montiranje."
              image={demontaza}
            />
            <ProgramSectionItem
              title="Montaža"
              message="Nakon demontaže, pranja i peglanja zavesa sledi montaža koju će naš tim montažera obaviti brzo i precizno. Zajedno se dogovaramo za vreme koje Vam najviše odgovara i obavljamo posao u kratkom roku."
              image={montaza}
            />
          </ProgramSection>
        </Container>
      </div>
    </>
  );
};

export default ServisZavesa;
