import React from 'react';
import { Container } from '../../components';
import photo1 from '../../assets/blog/article3/bele_zavese-1024x538.webp';
import photo2 from '../../assets/blog/article3/plave_zavese-1024x538.webp';
import photo3 from '../../assets/blog/article3/bele_zavese-1024x538.webp';
import photo4 from '../../assets/blog/article3/bez_zavese-1024x538.webp';
import photo5 from '../../assets/blog/article3/zelene_zavese-1024x538.webp';
import photo6 from '../../assets/blog/article3/narandzaste_zavese-1024x538.webp';
import photo7 from '../../assets/blog/article3/zute_zavese-1024x538.webp';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>
          Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu -
          DaLux.rs
        </title>
        <meta
          name="title"
          content="Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu - DaLux.rs"
        />
        <meta
          name="description"
          content="Saznajte kako da odaberete pravu boju zavesa i draperija za vaš prostor. Saveti za izbor plavih, belih, bež, narandžastih, zelenih i žutih zavesa."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/blog/kako-da-odaberete-boju-zavesa-i-draperija-za-vasu-sobu"
        />

        <meta
          property="og:title"
          content="Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu - DaLux.rs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/blog/kako-da-odaberete-boju-zavesa-i-draperija-za-vasu-sobu"
        />
        <meta property="og:site_name" content="DaLux.rs" />
        <meta
          property="og:description"
          content="Saznajte kako da odaberete pravu boju zavesa i draperija za vaš prostor. Saveti za izbor plavih, belih, bež, narandžastih, zelenih i žutih zavesa."
        />
        <meta property="og:locale" content="sr_RS" />
      </Helmet>

      <Container>
        <div
          style={{
            color: 'black',
            flexDirection: 'column',
            minHeight: '800px',
            paddingTop: '120px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            className={'articleBack'}
            onClick={() => {
              window.location = '/blog';
            }}
          >
            {'< Nazad na Blog'}
          </div>

          <div className={'articleTitle'}>
            <h1>
              Kako da odaberete odgovarajuću boju zavesa i draperija za vašu
              sobu
            </h1>
            {/* <small className={'articleDate'}>18. novembar, 2022.</small> */}
          </div>

          <p className={'articleBody'}>
            <img src={photo1} />
            Bez obzira na veličinu i izgled vaših prozora, izabrati odgovarajuću
            boju zavesa može biti, donekle, zahtevan posao. Pored toga što sama
            boja mora da vam se svidi, potrebno je i da se uklopi, ali i ulepša
            prostoriju. Pre svega je potrebno da se odlučite da li želite da se
            zavese potpuno “stope” u prostor, ili želite da napravite kontrast,
            kako bi se one isticale u prostoru. Kako biste lakše doneli odluku,
            za vas smo odabrali šest najpopularnijih boja zavesa koje, zaista,
            mogu ulepšati svaku sobu.
            <h3>Plave zavese</h3>
            Često se ističe da je u pitanju boja koja smiruje. Plava boja se
            izuzetno često koristi u dizajnu enterijera. Kada su u pitanju
            draperije plave boje, utiču na to da atmosfera u prostoriji bude
            opuštajuća.
            <img src={photo2} />
            <h3>Bele zavese</h3>
            Ne morate imati zavese jarkih boja kako biste oplemenili prostor.
            Zavese bele boje su pravi primer kako jedna od boja koje se najčešće
            biraju, čini da vaša soba odiše jednostavnošću i sofisticiranošću, a
            najbolji efekat se postiže sa okvirima prozora crne boje. Ukoliko u
            prostoriji imate više prozora, zavese bele boje su najbolja opcija
            jer neće dominirati enterijerom.
            <img src={photo3} />
            <h3>Bež zavese</h3>
            Ukoliko se pitate zašto su bež zavese toliko popularne, odgovor je
            veoma jednostavan. Zavese ove boje se lako uklapaju u svaki prostor,
            bez obzira na boju zidova ili nameštaja. Stoga, ako ste neodlučni,
            bež zavese su pravo rešenje za vas.
            <img src={photo4} />
            <h3>Narandžaste zavese</h3>
            Možda nije boja koja bi vam prva pala na pamet kada se radi o
            zavesama, ali mogu oplemeniti svaku sobu u zavisnosti od njene
            namene. Za spavaću sobu se, uglavnom, preporučuju malo nežniji
            tonovi poput breskve, dok su za dnevnu ili radnu sobu preporuka malo
            tamniji tonovi, kao što je boja ćilibara.
            <img src={photo5} />
            <h3>Zelene zavese</h3>
            Zelene zavese su odličan izbor za spavaću sobu. Pored toga što je u
            pitanju boja koja smiruje, može učiniti vašu prostoriju znatno
            prostranijom. Naravno ne treba zaboraviti da se sve stvar ličnih
            preferencija, te zelena boja zavesa nekima sigurno neće biti prvi
            izbor.
            <img src={photo6} />
            <h3>Žute zavese</h3>
            Svi bismo želeli da nam sve sobe budu “okupane Suncem”, ali je
            realnost ipak malo drugačija. Jedan od načina da se izborimo sa
            sivilom oblačnih dana su upravo zavese žute boje. Posebno su
            efikasne u prostorijama kojima nedostaje prirodno svetlo.
            <img src={photo7} />
            Nadamo se da smo vam pomogli da se lakše odlučite za boju zavesa
            koje će biti idealne za vaš dom. Kako bi vaše zavese u potpunosti
            oplemenile svaki prostor, pogledajte pet saveta za koji vam mogu
            pomoći da vaša soba bude još veća. Na veb-sajtu Dalux.rs možete
            videti i ponudu{' '}
            <a href="/zavese-i-draperije" target="_blank">
              zavesa i draperija
            </a>{' '}
            rađenih po meri, ili nas kontaktirajte.
          </p>
        </div>
      </Container>
    </>
  );
};
