export const termsAndConditions = [
  {
    title: 'Opšti uslovi kupovine u internet prodavnici',
    text: `Poštovani kupci,<br/>
    Obaveštavamo Vas da prilikom kupovine proizvoda na elektronskoj platformi: www.dalux.rs („Webshop“) važe sledeći uslovi kupovine navedeni u nastavku teksta, koje Vas molimo da pažljivo pročitate, iz razloga što ćete kupovinom proizvoda, potvrditi da ste ih pročitali, razumeli i da ste saglasni sa njima.<br/>
    Prodavac na Webshopu je ALEKSANDAR ANDONOVSKI PREDUZETNIK, GRAĐEVINSKA RADNJA DALUX BEOGRAD (ČUKARICA), sa registrovanim sedištem u Beogradu, ul. Kraljice Katarine 25, MB:54635770 (u daljem tekstu: „DALUX“).<br/>
    Potrošač je posetilac Webshop-a DALUX-a koji može biti registrovani korisnik ili „gost“ (u daljem tekstu: „Kupac“) a koji odabere barem jedan proizvod iz asortimana, ubaci ga u korpu, pošalje narudžbenicu i nakon potvrde prijema narudžbenice, plati proizvod ili odabere opciju plaćanja pouzećem, čime se smatra da je Kupovina obavljena odnosno da je zaključen Ugovor o prodaji robe.`,
  },
  {
    title: 'Dostava robe i eventualna ograničenja',
    text: `Kupovina se obavlja odabirom proizvoda, obavljivanjem specifikacije proizvoda (odabirom boje, dimenzije, količine), ubacivanjem u korpu i plaćanjem konačne cene putem internet platforme banke (objašnjeno u nastavku).<br/>
    Nakon što DALUX primi narudžbenicu, izdaće potvrdu prijema i očekivani rok isporuke, a ukoliko nije u mogućnosti da isporuči sve naručene proizvode u zakonskom roku, obavestiće Kupca blagovremeno (putem e-mail-a ili telefonskog poziva), sa naznakom proizvoda koji može isporučiti i predviđenom roku za isporuku preostalih proizvoda, pri čemu Kupac može u potpunosti odustati od kupovine svih proizvoda, ili delimično odustati od proizvoda koji DALUX ne može isporučiti u zakonskom roku.`,
  },
  {
    title: 'Cene proizvoda',
    text: `Istaknute cene proizvoda uključuju sve pripadajuće poreze, bez troškova dostave, i isražene su u RSD a važe isključivo za kupovinu putem interneta. Promocije i popusti iskazani u Webshop-u mogu se razlikovati od promocija i popusta dostupnih u radnji DALUX-a. Troškovi dostave zavise od vrednosti konačne narudžbine kao i mesta dostave proizvoda i metod obračuna troškova dostave, i biće prikazani na kraju postupka naručivanja, prilikom konačnog obračuna ukupne vrednosti narudžbine.`,
  },
  {
    title: 'Način plaćanja',
    text: `Kupac može izvršiti plaćanje robe na dva načina:<br/>
    - pouzećem, u gotovini, prilikom preuzimanja robe;<br/>
    - ili platnim karticama.<br/>`,
  },
  {
    title: 'Zaštita poverljivih podataka o transakciji',
    text: `Prilikom unošenja podataka o platnoj kartici, poverljive informacija se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Banca Intesa ad Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu. Kartice koje su prihvaćene u našoj online prodavnici su: Visa, Master Card i American Express.`,
  },
  {
    title: 'Povraćaj sredstava',
    text: `U slučaju vraćanja robe i povraćaja sredstava Kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, DALUX je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na zahtev DALUX-a obaviti povraćaj sredstava na račun korisnika kartice.`,
  },
  {
    title: 'Odustanak od narudžbine',
    text: `Kupac ima pravo da otkaže svoju naružbinu u roku od 3 dana nakon što je potvrđena, iz bilo kog razloga, bez dodatnih troškova. Nakon proteka ovog roka, Kupac nema pravo na odustanak od ugovora. Kupac ostvaruje pravo na odustanak od ugovora izjavom koju može dati na propisanom obrascu dostupnom na internet stranici, a DALUX je dužan da bez odlaganja izvrši povraćaj uplata koje je primio od Kupca, uključujući i troškove isporuke, a najkasnije u roku od 14 dana od dana kada je primio obrazac za odustanak.`,
  },
  {
    title: 'Povrat robe',
    text: `Ukoliko nakon preuzimanja pošiljke:<br/>
    - ustanovite da vam je greškom DALUX-a dostavljen proizvod koji niste naručili;<br/>
    - ustanovite da vam je greškom DALUX-a, pored proizvoda koji ste naručili, dostavljen i fakturisan i proizvod koji niste naručili ili ukoliko ste se iz nekog razloga predomislili i odustali od kupovine imate pravo da DALUX-u vratite sporne proizvode, a mi ćemo vam vratiti vaš novac ili proizvod zameniti za drugi proizvod, po vašoj želji.`,
  },
  {
    title: 'Politika reklamacija',
    text: `Ukoliko niste zadovoljni isporučenim proizvodom, imate pravo na reklamaciju u skladu sa Zakonom o zaštiti potrošača i Pravilnikom o uslovima i načinu rešavanja reklamacija potrošača DALUX-a koji je dostupan na internet stranici.`,
  },
  {
    title: 'Zaštita privatnosti korisnika',
    text: `U ime DALUX-a obavezujemo se da ćemo čuvati privatnost svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li žele ili ne da se izbrišu sa mailing lista koje se koriste za marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi zaposleni [ime prodajnog mesta] (i poslovni partneri) odgovorni su za poštovanje načela zaštite privatnosti.`,
  },
  {
    title: 'Izjava o konverziji',
    text: `Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD).Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke Srbije. 
    Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u 
    trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu.`,
  },
  {
    title: 'Izjava o PDV-u',
    text: `PDV uračunat u cenu i nema skrivenih troškova.`,
  },
  {
    title: 'Promene opštih uslova',
    text: `DALUX zadržava pravo da sve gore navedene uslove promeni.<br/>
    O svim izmenama ovih opštih uslova poznati korisnici će biti obavešteni putem njihove registrovane e-mail adrese. Smatra se da su korisnici upoznati sa promenama opštih uslova prilikom svake sledeće porudžbine, nakon slanja obaveštenja.`,
  },
  {
    title: 'Osnovni podaci o firmi',
    text: `Poslovno ime: ALEKSANDAR ANDONOVSKI PREDUZETNIK, GRAĐEVINSKA RADNJA DALUX BEOGRAD (ČUKARICA)<br/>
    Registrovana adresa sedišta: Kraljice Katarine 25, 11000 Beograd<br/>
    Delatnost: Proizvodnja gotovih tekstilnih proizvoda osim odeće.<br/>
    Šifra delatnosti: 4332 i 1392<br/>
    Matični broj: 54635770<br/>
    Poreski broj: 100419232<br/>
    Web adresa: www.dalux.rs<br/>
    Tel: +381 65-1185-059<br/>
    E-mail: info@dalux.rs`,
  },
];
