import React from 'react';
import { Container } from '../../components';
import photo1 from '../../assets/blog/article2/naslovna-min-855x500.webp';
import photo2 from '../../assets/blog/article2/1.png';
import photo3 from '../../assets/blog/article2/zavese i draperije (2).png';
import photo4 from '../../assets/blog/article2/3.png';
import photo5 from '../../assets/blog/article2/zavese i draperije 3..png';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>
          Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu -
          DaLux.rs
        </title>
        <meta
          name="title"
          content="Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu - DaLux.rs"
        />
        <meta
          name="description"
          content="Saznajte kako da odaberete pravu boju zavesa i draperija za vaš prostor. Praktični saveti za klasičan, moderan i minimalistički stil enterijera."
        />
        <link
          rel="canonical"
          href="https://www.dalux.rs/blog/kako-da-odaberete-boju-zavesa-i-draperija-za-vasu-sobu"
        />

        <meta
          property="og:title"
          content="Kako da odaberete odgovarajuću boju zavesa i draperija za vašu sobu - DaLux.rs"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.dalux.rs/blog/kako-da-odaberete-boju-zavesa-i-draperija-za-vasu-sobu"
        />
        <meta property="og:site_name" content="DaLux.rs" />
        <meta
          property="og:description"
          content="Saznajte kako da odaberete pravu boju zavesa i draperija za vaš prostor. Praktični saveti za klasičan, moderan i minimalistički stil enterijera."
        />
        <meta property="og:locale" content="sr_RS" />
      </Helmet>

      <Container>
        <div
          style={{
            color: 'black',
            flexDirection: 'column',
            minHeight: '800px',
            paddingTop: '120px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            className={'articleBack'}
            onClick={() => {
              window.location = '/blog';
            }}
          >
            {'< Nazad na Blog'}
          </div>

          <div className={'articleTitle'}>
            <h1>
              Kako da odaberete odgovarajuću boju zavesa i draperija za vašu
              sobu
            </h1>
            {/* <small className={'articleDate'}>18. novembar, 2022.</small> */}
          </div>

          <p className={'articleBody'}>
            <img src={photo1} />
            Odabrati prave zavese za vaš stan, kuću sobu ili poslovni prostor
            može biti izuzetno naporan zadatak. Kako biste efikasnije doneli
            odluku, pripremili smo određena pitanja na koja treba da odgovorite
            i kako bi boja zavese ili draperije koju odaberete dodatno
            oplemenila vaš prostor.
            <br />
            <br />
            <img src={photo2} />
            Šta je ono što zapravo tražite? Da li vam više odgovara klasičan,
            moderan ili, pak, minimalistički stil zavesa? Da li više volite
            dezen koji opušta i smiruje ili onaj koji treba da “podigne
            atmosferu” prostorije? Da li želite da zavese budu centralni element
            i dominiraju prostorom ili želite da se uklope u enetrijer.
            <br />
            <img src={photo3} />
            <br />
            <br />
            <h3>Suzite vašu pretragu</h3>
            Tek kada imate odgovore na navedena pitanja možete suziti{' '}
            <a href="/zavese-i-draperije" target="_blank">
              izbor zavesa.
            </a>
            . Ukoliko ste odabrali klasičan stil, praktično sami birate da li
            želite da se dezen i boja zavese uklapa u prostor ili želite da
            pravite kontrast u odnosu na boju zida u prostoriji. Ne možete
            pogrešiti ukoliko uklopite boju vaših zavesa sa bojom nameštaja.
            <br />
            <img src={photo4} />
            <br />
            Za one koji preferiraju moderan i minimalistički stil, preporučujemo
            zavese u boji blago tamnijoj od boje zida. Najčešće su to providne
            zavese. Ako ste u potrazi za bojom ili dezenom zavesa koji opušta i
            smiruje, predlažemo da odaberete zavese sa svetlom nijansom sive
            boje. Takođe, pokušajte da uklopite boju zavesa sa nameštajem ili
            bojem poda, što će dodatno doprineti da vaša prostorija deluje
            izuzetno udobno. Za sve one koji su u potrazi za{' '}
            <a href="/zavese-i-draperije" target="_blank">
              zavesama ili draperijama
            </a>{' '}
            koje odišu elegancijom, braon boja je pravi izbor.
            <br />
            <img src={photo5} />
            <br />
            Na kraju krajeva, nećete pogrešiti ni sa jednom bojom zavese ili
            draperije za koju smatrate da će na najbolji način ulepšati vaš
            prostor. Nadamo se da smo uspeli da vam pomognemo pri odabiru boje
            ili dezena zavese za vaš dom.
          </p>
        </div>
      </Container>
    </>
  );
};
