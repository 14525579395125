import React from 'react';
import ErrorPhoto from '../../assets/ErrorPhoto.webp';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Stranica ne postoji - 404 | DaLux</title>
        <meta name="title" content="404 - Stranica ne postoji | DaLux" />
      </Helmet>
      <div
        style={{
          minHeight: 'calc(100vh - 100px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        <img
          src={ErrorPhoto}
          alt="Stranica nije pronađena"
          style={{
            maxWidth: '500px',
            maxHeight: '500px',
            width: '50vw',
            height: '50vw',
            objectFit: 'contain',
            '@media (max-width: 768px)': {
              width: '80vw',
              maxWidth: '700px',
              maxHeight: '700px',
            },
          }}
        />
      </div>
    </>
  );
};

export default NotFoundPage;
