import React, { useContext, useEffect } from 'react';
import { ShopContainer, Sidebar, ShopWrapper } from './Shop.styled';
import Box from '@mui/material/Box';
import StepLabel from '@mui/material/StepLabel';
import { Stack } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Product } from '../../components/ShopNew/Product';
import { Products } from '../../components/ShopNew/Products';
import { UserInfo } from '../../components/ShopNew/UserInfo';
import { CartContext } from '../../CartContext';
import { OrderReview } from '../../components/ShopNew/OrderReview';
import { CartItem } from '../../components/ShopNew/CartItem';

import { useWindowSize } from '@uidotdev/usehooks';
import { Payment } from '../../components/ShopNew/Payment';
import { products } from '../../helpers/products';
import MetaPixel from '../../helpers/MetaPixel';
import { toast } from 'react-toastify';

import { Helmet } from 'react-helmet';

import axios from 'axios';

export const ShopNew = ({}) => {
  const [product, setProduct] = useState(null);

  const size = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const productQuery = searchParams.get('product');

  const navigate = useNavigate();

  const {
    cart,
    cartPage,
    setCartPage,
    userInfo,
    removeFromCart,
    legal,
    paymentType,
  } = useContext(CartContext);

  useEffect(() => {
    if (cart.length === 0) {
      if (product) {
        setCartPage(1);
      } else {
        setCartPage(0);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (productQuery && products.some((p) => p.id === productQuery)) {
      setCartPage(1);
      setSearchParams({ step: 1 });
      setProduct(products.find((p) => p.id === productQuery));
    } else if (productQuery) {
      navigate('/shop');
    }
  }, []);

  useEffect(() => {
    const step = searchParams.get('step');
    setCartPage(Number(step));
    if (!product && step !== '0' && !productQuery && cart.length === 0) {
      navigate('/shop');
    }
  }, [searchParams, product, productQuery]);

  const steps = ['Izaberi', 'Specifikacije', 'Informacije', 'Plaćanje'];

  const handleBack = () => {
    if (cartPage >= 0) {
      setSearchParams({ step: cartPage - 1 });
    }
    if (cartPage === 1) {
      if (productQuery) {
        navigate('/shop');
      }
      setProduct(null);
      setCartPage(0);
    } else if (cartPage === 2) {
      if (product) {
        setCartPage(1);
      } else {
        setCartPage(0);
      }
    } else if (cartPage === 3) {
      setCartPage(2);
    } else if (cartPage === 4) {
      setCartPage(3);
    } else if (cartPage === 5) {
      setCartPage(4);
    }
  };

  const handleNext = async () => {
    if (cartPage === 1 && cart.length) {
      setSearchParams({ step: cartPage + 1 });
      setCartPage(2);
    }

    if (cartPage === 2 && userInfo) {
      setSearchParams({ step: cartPage + 1 });
      setCartPage(3);
    }
    if (cartPage === 3 && userInfo) {
      setSearchParams({ step: cartPage + 1 });
      setCartPage(4);
    }
    if (cartPage === 4 && legal && paymentType) {
      // prvo pozvati api za order
      await handleOrder();
      setSearchParams({ step: cartPage + 1 });
      setCartPage(5);
    }
  };

  const handleOrder = async () => {
    const order = {
      userInfo: {
        first_name: userInfo.name,
        last_name: userInfo.surname,
        street: userInfo.street,
        house_number: userInfo.houseNo,
        apartment_number: userInfo.apartmentNo,
        postal_code: userInfo.postNo,
        city: userInfo.city,
        phone_number: userInfo.phone,
        email: userInfo.email,
        additional_info: userInfo.info,
      },
      products: cart.map((product) => {
        return {
          id: product.id,
          height: product.height,
          width: product.width,
          color: product.color,
          side: product.side,
          price: product.price,
          quantity: product.quantity,
          colorAround: product.colorAround,
          transparency: product.transparency,
        };
      }),
      paymentType,
      totalPrice_fe: cart?.reduce((acc, p) => acc + p.price, 0),
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders`,
        order
      );
      window.location = `/shop/order/${data.id}`;
      localStorage.clear();
    } catch (error) {
      //TODO handle error
    }
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "url": "https://www.dalux.rs/shop",
    "itemListElement": [{
            "@type": "Product",
            "name": "Komarnici",
            "image": "https://www.dalux.rs/images/shop/komarnik/komarnik-Bela.webp",
            "description": "Komarnici sprečavaju ulazak insekata u Vaš dom. Omogoćavaju nesmetano provetravanje prostorije. Zadržavaju prašinu i alergene spolja. Rok isporuke: 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=plise-komarnici",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Mini Zebra zavese (bez bušenja)",
            "image": "https://www.dalux.rs/images/shop/zebra/AbuDhabi.webp",
            "description": "Zebra zavese su vrsta rolo zavese sa kombinovanim (zebra) platnom. Pomeraju se gore-dole, namotavanjem na osovinu. Prilikom pomeranja zavese dolazi do različite svetlopropusnosti. Na ovaj način jednostavno kontrolišete količinu svetla u prostoriji i štitite se od neželjenih pogleda. Rok isporuke: 5-7 dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=zebra-zavese",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Plise zavese",
            "image": "https://www.dalux.rs/images/shop/pliseZavese/bela/Svetlo siva - 1401.webp",
            "description": "Plise zavese su vrsta zavese koja se pomeraju gore-dole. Pružaju elegantan i moderan izgled. Sprečavaju zadržavanje prašine i alergena u Vašem domu. Blokiraju sunčevu svetlost i utiču na temperaturu. Rok dostave je 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=plise-zavese",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "prailability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Zebra zavese sa maskom",
            "image": "https://www.dalux.rs/images/shop/zebraSaMaskom/bela/AbuDhabi.webp",
            "description": "Zebra zavese sa maskom predstavljaju funkcionalno i estetski privlačno rešenje za dekoraciju Vašeg prostora. Omogućavaju jednostavno podešavanje svetlo-propusnosti, istovremeno utičući na privatnost. Pomeraju se gore-gole namotavanjem na osovinu. Rok isporuke: 5-7 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=zebra-sa-maskom",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Zebra zavese bez maske",
            "image": "https://www.dalux.rs/images/shop/zebraBezMaske/bela/AbuDhabi.webp",
            "description": "Zebra zavese bez maske predstavljaju funkcionalno i estetski privlačno rešenje za dekoraciju Vašeg prostora. Omogućavaju jednostavno podešavanje svetlo-propusnosti, istovremeno utičući na privatnost. Pomeraju se gore-gole namotavanjem na osovinu. Rok isporuke: 5-7 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=zebra-bez-maske",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Paketo (rimske) zavese",
            "image": "https://www.dalux.rs/images/shop/paketo/paketo-bela.webp",
            "description": "Rimske (paketo) zavese predstavljaju elegantno i praktično rešenje za dekoraciju Vašeg prostora. Materijal koji se koristi za izradu ovih zavesa je imitacija lana koji Vam omogućava da jednostavno prilagodite svetlo i privatnost u svojim prostorijama. Rok isporuke je 10-14 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=paketo-zavese",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "Product",
            "name": "Kukice okrugle",
            "image": "https://www.dalux.rs/images/shop/kukice/KukiceOkruglaZlatna.webp",
            "description": "Kukice za zavese olakšavaju postavljanje i dekoraciju. Dolaze u paru u zlatnoj ili srebrnoj boji. Rok isporuke je 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
            "url": "https://www.dalux.rs/shop?product=kukice-okrugle",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RSD",
                "price": "cena se može naći na sajtu",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "DaLux"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 8,
            "item": {
                "@type": "Product",
                "name": "Kukice dijamantske",
                "image": "https://www.dalux.rs/images/shop/kukice/KukiceDijamantZlatna.webp",
                "description": "Kukice za zavese olakšavaju postavljanje i dekoraciju. Dolaze u paru u zlatnoj ili srebrnoj boji. Rok isporuke je 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
                "url": "https://www.dalux.rs/shop?product=kukice-dijamantske",
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "RSD",
                    "price": "cena se može naći na sajtu",
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                        "@type": "Organization",
                        "name": "DaLux"
                    }
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 9,
            "item": {
                "@type": "Product",
                "name": "Plafonske ALU garnišne",
                "image": "https://www.dalux.rs/images/shop/garnisne/garnisne-Dvokanalna.webp",
                "description": "Plafonske ALU garnišne su poseban tip držača za zavese koji se montira na plafon. Ovi držači omogućavaju elegantno kačenje zavesa bez potrebe za bušenjem zidova. Lako se postavljaju i omogućavaju Vam da prilagodite izgled prozora. Rok isporuke je 7-10 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
                "url": "https://www.dalux.rs/shop?product=garnisne",
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "RSD",
                    "price": "cena se može naći na sajtu",
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                        "@type": "Organization",
                        "name": "DaLux"
                    }
                }
            }
        }
    ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Shop",
      "url": "https://www.dalux.rs/shop",
      "description": "Pogledajte našu ponudu proizvoda i kupite online.",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs/"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Shop",
            "item": "https://www.dalux.rs/shop"
          }
        ]
      }
    }`}
        </script>
      </Helmet>
      <MetaPixel />
      <ShopContainer activeStep={cartPage}>
        <ShopWrapper activeStep={cartPage}>
          <Stepper
            activeStep={cartPage >= 3 ? cartPage - 1 : cartPage}
            alternativeLabel
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {cartPage === 0 && (
            <Products
              setProduct={(product) => {
                setProduct(product);
                setCartPage(1);
                setSearchParams({ step: 1 });
              }}
            />
          )}
          {cartPage === 1 && <Product product={product} />}
          {cartPage === 2 && <UserInfo />}
          {cartPage === 3 && <OrderReview />}
          {cartPage === 4 && <Payment />}

          {cartPage !== 0 && (
            <div className="btns_wrapper">
              <div className="btns">
                <button
                  className="prev"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Nazad
                </button>

                <button
                  className="next"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Dalje
                </button>
              </div>
            </div>
          )}
        </ShopWrapper>
        {size.width >= 1400 && (
          <Sidebar>
            <div className="cart_icon">
              <img src="/images/cart-white.svg" alt="cart" />
              <p>Pregled korpe:</p>
            </div>
            <Stack gap={2}>
              {cart.length > 0 &&
                cart.map((cartProduct) => {
                  return (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <CartItem cartProduct={cartProduct} />
                      <img
                        src="/images/trash.png"
                        alt="trash"
                        height={28}
                        className="trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          removeFromCart(cartProduct);
                          toast.error('Proizvod je obrisan iz korpe', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                          });
                        }}
                      />
                    </Box>
                  );
                })}
            </Stack>
            {cartPage !== 0 && cart.length > 0 && (
              <button
                className="add_item"
                onClick={() => {
                  if (productQuery) {
                    navigate('/shop');
                  }
                  setCartPage(0);
                  setProduct(null);
                  setSearchParams({ step: 0 });
                }}
              >
                + DODAJ PROIZVOD
              </button>
            )}
            {cart.length > 0 && (
              <>
                <p className="total_price">
                  Ukupna cena:{' '}
                  {cart.reduce((acc, p) => acc + p.price, 0).toFixed(2)} RSD
                </p>
                <button
                  className="proceed_to_checkout"
                  onClick={() => {
                    navigate('/shop?step=2');
                    window.scrollTo(0, 0);
                  }}
                >
                  Nastavi ka plaćanju
                </button>
              </>
            )}
          </Sidebar>
        )}
      </ShopContainer>
    </>
  );
};
