import { createContext, useState, useEffect } from 'react';

import { getCart } from './helpers/products';

export const CartContext = createContext({
  legal: false,
  setLegal: () => {},
  cartPage: 0,
  setCartPage: () => {},
  userInfo: {},
  setUserInfo: () => {},
  cart: [],
  addToCart: () => {},
  removeFromCart: () => {},
  paymentType: null,
  setPaymentType: () => {},
});

export const CartProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(0);
  const [cartPage, setCartPage] = useState(0);
  const [paymentType, setPaymentType] = useState(null);
  const [legal, setLegal] = useState(false);
  const [cart, setCart] = useState(getCart());

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (p) => {
    setCart((oldCart) => [
      ...oldCart,
      {
        id: p.id,
        rnd: Date.now(),
        width: p.width,
        height: p.height,
        color: p.color,
        colorAround: p.colorAround,
        price: p.price,
        side: p.side,
        quantity: p.quantity,
        transparency: p.transparency,
      },
    ]);
  };

  const removeFromCart = (p) => {
    const cartCopy = [...cart];

    for (let i = 0; i < cartCopy.length; i++) {
      if (cartCopy[i].id === p.id && cartCopy[i].rnd === p.rnd) {
        cartCopy.splice(i, 1);
        break;
      }
    }

    setCart(cartCopy);
  };

  return (
    <CartContext.Provider
      value={{
        userInfo,
        setUserInfo,
        cartPage,
        setCartPage,
        cart,
        addToCart,
        removeFromCart,
        legal,
        setLegal,
        paymentType,
        setPaymentType,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
