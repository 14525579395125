import { products } from '../../helpers/products';
import { Box, Stack } from '@mui/material';

export const CartItem = ({ cartProduct }) => {
  const product = products.find((p) => p.id === cartProduct.id);
  // console.log('Product: ', product);
  // console.log('cartProduct: ', cartProduct);

  const isPliseOrZebra = [
    'plise-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
  ].includes(cartProduct.id);
  const isZebraTypesOrPaketo = [
    'zebra-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
    'paketo-zavese',
  ].includes(cartProduct.id);
  const noDimensions = ['kukice-okrugle', 'kukice-dijamantske'].includes(
    cartProduct.id
  );
  const colorAroundProducts = [
    'plise-zavese',
    'zebra-sa-maskom',
    'zebra-bez-maske',
  ];

  return (
    <Box display="flex" gap={1.5} className="outerDiv">
      <div>
        <img
          src={
            colorAroundProducts.includes(product.id) &&
            cartProduct.color &&
            cartProduct.colorAround
              ? product.imageColor
                  .replace('{color}', cartProduct.color)
                  .replace(
                    '{colorAround}',
                    cartProduct.colorAround.toLowerCase()
                  )
              : cartProduct.color
              ? product.imageColor.replace('{color}', cartProduct.color)
              : product.img
          }
          alt="preview"
          height="44px"
          width="44px"
          className="item"
        />
      </div>
      <Stack>
        <p>
          <span>Naziv: </span>
          {product.title}
        </p>
        <p>
          <span>Boja: </span>
          {cartProduct.color}
        </p>
        {cartProduct.id === 'plise-zavese' && (
          <p>
            <span>Propustljivost: </span>
            {cartProduct.transparency}
          </p>
        )}
        {isPliseOrZebra && (
          <p>
            <span>Boja profila: </span>
            {cartProduct.colorAround}
          </p>
        )}
        {!noDimensions && (
          <p>
            <span>Dimenzija: </span>
            {cartProduct.width}
            {cartProduct.id !== 'garnisne' && ` x ${cartProduct.height}`}
          </p>
        )}
        {isZebraTypesOrPaketo && (
          <p>
            <span>Orijentacija lanca: </span>
            {cartProduct.side}
          </p>
        )}
        <p>
          <span>Količina: </span>
          {cartProduct.quantity}
        </p>
        <p>
          <span>Cena: </span>
          <span style={{ color: '#FFC700' }}>
            {cartProduct.price.toFixed(2)}
          </span>{' '}
          RSD
        </p>
      </Stack>
    </Box>
  );
};
