import React from 'react';
import './sliderKnobs.css';

const SliderKnobs = ({ selected, sliders }) => {
  return (
    <div className="sliderKnobs">
      {[...new Array(sliders)].map((_, index) => (
        <div
          className={`sliderKnob ${selected === index ? 'selected' : ''}`}
          key={index}
        />
      ))}
    </div>
  );
};

export default SliderKnobs;
