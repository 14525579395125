import React from 'react';
import { Faq, Slider } from '../../components';

import SlideFirst from '../../assets/rimske/1.png';
import SlideSecond from '../../assets/rimske/2.png';
import SlideThird from '../../assets/rimske/3.webp';
import SlideFourth from '../../assets/rimske/4.webp';
import SlideFifth from '../../assets/rimske/5.webp';
import SlideSixth from '../../assets/rimske/6.webp';
import SlideSeventh from '../../assets/rimske/7.webp';

import SlideFirstPhone from '../../assets/rimske_mobile/roman1.webp';
import SlideSecondPhone from '../../assets/rimske_mobile/roman2.webp';
import SlideThirdPhone from '../../assets/rimske_mobile/roman3.webp';
import SlideFourthPhone from '../../assets/rimske_mobile/roman4.webp';
import SlideFifthPhone from '../../assets/rimske_mobile/roman5.webp';
import SlideSeventhPhone from '../../assets/rimske_mobile/roman7.webp';
import { Helmet } from 'react-helmet';

const desktop = window.innerWidth > 600 ? true : false;

const SLIDERS = [
  {
    title: 'Rimske zavese',
    message: 'Ulepšavaju izgled svakog prostora.',
    image: desktop ? SlideFirst : SlideFirstPhone,
  },
  {
    title: 'Rimske zavese',
    message: 'Veliki izbor materijala, boja i dezena.',
    image: desktop ? SlideSecond : SlideSecondPhone,
  },
  {
    title: 'Rimske zavese',
    message: 'Jednostavne i zahvalne za održavanje.',
    image: desktop ? SlideThird : SlideThirdPhone,
  },
  {
    title: 'Rimske zavese',
    message: 'Izrađuju se prema vašim dimenzijama.',
    image: desktop ? SlideFourth : SlideFourthPhone,
  },
  {
    title: 'Rimske zavese',
    message: 'Proširuju i stvaraju osećaj većeg prostora.',
    image: desktop ? SlideFifth : SlideFifthPhone,
  },
  {
    title: 'Rimske zavese',
    message: 'Mogu se zaustaviti u svakom položaju.',
    image: SlideSixth,
  },
  {
    title: 'Rimske zavese',
    message: 'Spuštene ili podignute - imaju elegantan izgled.',
    image: desktop ? SlideSeventh : SlideSeventhPhone,
  },
];

const Rimske = () => {
  return (
    <>
      <Helmet>
        <title>Rimske zavese - Paketo - DaLux</title>
        <meta name="title" content="Rimske zavese - Paketo - DaLux" />
        <meta
          name="description"
          content="Rimske ili paketo zavese su jedan od najpopularnijih načina kačenja zavesa. Širok izbor boja i dezena pogledajte na sajtu Dalux.rs."
        />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://www.dalux.rs/rimske-zavese" />

        <meta property="og:title" content="Rimske zavese - Paketo - DaLux" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dalux.rs/rimske-zavese" />
        <meta property="og:site_name" content="dalux.rs" />
        <meta
          property="og:description"
          content="Rimske ili paketo zavese su jedan od najpopularnijih načina kačenja zavesa. Širok izbor boja i dezena pogledajte na sajtu Dalux.rs."
        />
        <meta property="og:locale" content="sr_RS" />
        <meta
          property="og:image"
          content="https://www.dalux.rs/static/media/1.17f22eee29411390f979.png"
        />
        <meta property="og:image:width" content="2880" />
        <meta property="og:image:height" content="1620" />

        <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Rimske zavese - Paketo - DaLux",
      "description": "Rimske ili paketo zavese su jedan od najpopularnijih načina kačenja zavesa. Širok izbor boja i dezena pogledajte na sajtu Dalux.rs.",
      "url": "https://www.dalux.rs/rimske-zavese",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Početna",
            "item": "https://www.dalux.rs"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Rimske zavese",
            "item": "https://www.dalux.rs/rimske-zavese"
          }
        ]
      }
    }
  `}</script>

        <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Rimske zavese",
      "image": "https://www.dalux.rs/images/shop/paketo/paketo-bela.webp",
      "description": "Rimske (paketo) zavese predstavljaju elegantno i praktično rešenje za dekoraciju Vašeg prostora. Materijal koji se koristi za izradu ovih zavesa je imitacija lana koji Vam omogućava da jednostavno prilagodite svetlo i privatnost u svojim prostorijama. Rok isporuke je 10-14 radnih dana. Fiskalni račun se dobija u paketu. Troškove dostave snosi kupac.",
      "url": "https://www.dalux.rs/shop?product=paketo-zavese",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "RSD",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "DaLux"
        }
      }
    }
  `}</script>
      </Helmet>
      <Slider sliders={SLIDERS} />

      <Faq
        questions={[
          {
            title: 'Koja je cena rimskih zavesa?',
            message:
              'Mehanizam za paketo zavese iznosi 4.200 rsd/m, šivenje se naplaćuje 2.500 rsd/kom i preostaje da izaberete materijal koji želite da stavimo u paketo mehanizam. Cena materijala se kreće od 1.800 rsd/m do 4.500 rsd/m.',
          },
          {
            title: 'Šta je potrebno da bi mogle da se montiraju rimske zavese?',
            message:
              'Potrebno je da iznad krila prozora postoji minimum 20cm praznog prostora kako bi zavesa mogla da se podigne u gornji položaj te da ne smeta prozoru prilikom otvaranja.',
          },
          {
            title:
              'Da li paketo mehanizam može da se montira direktno na krilo prozora?',
            message:
              'Nažalost ne. Radimo na tome da uvedemo i taj sistem u našu prodaju.',
          },
          {
            title: 'Koje vrste platna mogu da se koriste za rimske zavese?',
            message:
              'Za rimske paketo zavese mogu da se koriste sva platna koja mi imamo u prodaji međutim najbolje su se pokazali materijali od 100% prirodnog lana kao i imitacije lana.',
          },
          {
            title: 'Kako se održavaju rimske zavese?',
            message:
              'Rimske zavese se održavaju na isti način kao i zavese i draperije. Platno možete da perete u veš mašini na 30 stepeni bez centrifuge.',
          },
          {
            title:
              'Da li je moguće kasnije samo zameniti platno na rimskim zavesama?',
            message: 'Naravno, platno možete uvek naknadno promeniti.',
          },
          {
            title: `Da li mogu da stavim rimske zavese ukoliko mi prozori idu skroz do plafona?`,
            message: `Nažalost, u ovoj situaciji nije moguće instalirati paketo zavese pošto mehanizam zahteva minimum 25cm praznog prostora iznad stolarije.`,
          },
          {
            title: 'Koliki je rok isporuke?',
            message:
              'Rok isporuke za rimske zavese je 2-4 nedelje od momenta kada se uplati avans.',
          },
        ]}
        faqImg={SlideFirstPhone}
      />
    </>
  );
};

export default Rimske;
